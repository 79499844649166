import { Box, Container, Theme, Typography, makeStyles, useMediaQuery } from '@material-ui/core';
import React, { FC } from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.up('lg')]: {
      borderTop: '1px solid #D3D3D3',
      marginTop: '32px',
    },
  },
  copyrightBox: {
    backgroundColor: '#D3D3D3',
    color: '#888',
    display: 'flex',
    flexDirection: 'column',
    fontSize: 12,
    '& span.divide': {
      padding: '0 5px',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '1040px',
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: '32px',
      margin: '0 auto',
      backgroundColor: '#fff',
      paddingBottom: '100px',
      fontSize: '14px',
      lineHeight: '22px',
      '& span > span': {
        fontWeight: 500,
      },
      '& span.divide': {
        padding: '0 8px',
      },
    },
  },
}));

const Footer: FC = () => {
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container maxWidth="lg" style={{ padding: 0 }}>
        <Box className={classes.copyrightBox} mt={1} px={2} py={3}>
          <span>
            <span>회사명</span> 주식회사 스패너 (Xpanner Inc.)
          </span>
          <span>
            <span>대표자</span> 이명한, 신흥주, 박주한
            <span className="divide">|</span>
            <span>사업자등록번호</span> 452-86-01771
          </span>
          <span>
            <span>직업정보제공사업면허 신고번호</span> J1200020230027
          </span>
          <span>
            <span>본사</span> 서울특별시 성동구 성수일로 77, 1603호, 1604호(성수동1가, 서울숲아이티밸리)
            {lgUp ? <span className="divide">|</span> : <br />}
            <span>전화번호</span> 02-6956-1778
          </span>
          <span>
            <span>이메일</span> cs@xpanner.com
          </span>
        </Box>
      </Container>
    </div>
  );
};

export default Footer;
