import { combineReducers } from '@reduxjs/toolkit';
import dialog from './dialogSlice';
import kakaoPostcode from './kakaoPostcodeSlice';
import shippingAddrMng from './shippingAddrMngSlice';
import sweettrackerTracking from './sweettrackerTrackingSlice';

const xpnrReducer = combineReducers({
  dialog,
  kakaoPostcode,
  sweettrackerTracking,
  shippingAddrMng,
});

export default xpnrReducer;
