import { Badge, BottomNavigation, BottomNavigationAction, makeStyles, withStyles } from '@material-ui/core';
import BuildIcon from '@material-ui/icons/Build';
import StorefrontIcon from '@material-ui/icons/Storefront';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import { FC, useEffect, useState } from 'react';
import { Menu as MenuIcon } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import { URI_CONFIG } from 'src/config';

const useStyles = makeStyles((theme) => ({
  root: {
    ...(/iPhone|iPad|iPod/i.test(navigator.userAgent)
      ? {
          paddingBottom: 15,
          height: 'auto',
        }
      : {}),
    bottom: 0,
    width: '100%',
    position: 'fixed',
    borderTop: `1px solid ${theme.palette.divider}`,
    '& .MuiBottomNavigationAction-root.Mui-selected': {
      color: theme.palette.primary.main,
    },
    '& .MuiBottomNavigationAction-label.Mui-selected': {
      fontSize: '0.75rem',
    },
    zIndex: theme.zIndex.drawer + 2,
  },
}));

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: -4,
    top: 4,
    border: `1px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
    fontSize: 10,
    height: 17,
    backgroundColor: '#999999',
  },
}))(Badge);

const isProd = window.location.hostname === 'store.xpanner.com'; // 운영환경여부..

const XpnrBottomNavigation: FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [value, setValue] = useState('finder');

  // finder_mo 이동
  const goFinderMoSite = () => {
    window.location.href = URI_CONFIG.finder_mo_uri;
  };

  // Rent_worker 이동
  const goRentWorkerSite = () => {
    window.location.href = URI_CONFIG.rent_worker_uri;
  };

  useEffect(() => {
    if (window.location.href.indexOf('mypage') === -1) {
      setValue('store');
    } else {
      setValue('mypage'); // TODO: mypage 분리 시 소스코드 수정
    }
  });

  return (
    <BottomNavigation showLabels className={classes.root} value={value}>
      <BottomNavigationAction
        label="렌트"
        icon={
          <StyledBadge badgeContent="Beta" color="primary" overlap="rectangular">
            <WorkOutlineIcon />
          </StyledBadge>
        }
        onClick={goRentWorkerSite}
        value="rent"
      />
      <BottomNavigationAction
        label="스토어"
        icon={<StorefrontIcon />}
        onClick={() => navigate('/store/main')}
        value="store"
      />
      <BottomNavigationAction label="파인더" icon={<BuildIcon />} onClick={goFinderMoSite} value="finder" />
      <BottomNavigationAction
        label="전체"
        icon={<MenuIcon />}
        onClick={() => {
          window.location.href = `${URI_CONFIG.mypageUrl}`;
        }}
        value="mypage"
      />
    </BottomNavigation>
  );
};

export default XpnrBottomNavigation;
