import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import shippingAddressApi from 'src/api/ShippingAddressApi';
import { CheckoutRequest, PaymentMethod } from 'src/types/checkout';
import { ShippingAddressDTO } from 'src/types/shippingAddress';
import SnackBarUtils from 'src/utils/SnackBarUtils';
import XpnrUtils from 'src/utils/XpnrUtils';

interface CheckoutState extends CheckoutRequest {
  consentOrNot: boolean;
  paymentMethod: PaymentMethod | '';
  payId: string;
  productOrderId: string;
}

export const initialState: CheckoutState = {
  checkoutList: [],
  addressList: [],
  shippingAddress: {
    shippingAddressId: '',
    shippingAddressNm: '',
    receiver: '',
    phone: '',
    zipCode: '',
    address: '',
    addressDetail: '',
    shippingMemo: '',
    defaultYn: true,
  },
  usePoint: 0,
  paymentMethod: '',
  cashReceipts: 'no',
  consentOrNot: false,
  listPrice: 0,
  discountAmount: 0,
  subTotalAmount: 0,
  shippingFee: 0,
  taxAmount: 0,
  totalAmount: 0,
  payAmount: 0,
  payId: '',
  productOrderId: '',
};

/* 배송지 목록 조회 */
export const getListShippingAddress = createAsyncThunk<ShippingAddressDTO[]>(
  'checkout/shippingAddressDTO',
  async () => {
    try {
      const response = await shippingAddressApi.getListShippingAddress();

      if (!response) {
        return [];
      }
      return response;
    } catch (err) {
      return err;
    }
  }
);

/* 배송지 삭제 */
export const deleteShippingAddress = createAsyncThunk<
  string,
  {
    shippingAddressId: string;
  }
>('checkout/deleteShippingAddress', async ({ shippingAddressId }, { rejectWithValue }) => {
  try {
    if (!shippingAddressId) {
      throw new Error('shippingAddressId Parameter is undefined!!');
    }
    const response = await shippingAddressApi.deleteShippingAddress(shippingAddressId);
    return shippingAddressId;
  } catch (err) {
    return XpnrUtils.catchThunkErrorHandler(err, rejectWithValue);
  }
});

/* 배송지 수정 및 등록 */
export const saveShippingAddress = createAsyncThunk<ShippingAddressDTO, { addressInfo: ShippingAddressDTO }>(
  'checkout/saveShippingAddress',
  async ({ addressInfo }, { rejectWithValue }) => {
    try {
      if (addressInfo.shippingAddressId === '') {
        const response = await shippingAddressApi.insertShippingAddress(addressInfo);
        return addressInfo;
      }
      const response = await shippingAddressApi.updateShippingAddress(addressInfo);
      return addressInfo;
    } catch (err) {
      SnackBarUtils.error(err.data.msg);
      return XpnrUtils.catchThunkErrorHandler(err, rejectWithValue);
    }
  }
);

/* 최근 배송지 조회 */
export const getLatestShippingAddress = createAsyncThunk<ShippingAddressDTO>(
  'checkout/getLatestShippingAddress',
  async () => {
    try {
      const response = await shippingAddressApi.getLatestShippingAddress();
      if (!response) {
        return initialState.shippingAddress;
      }
      return response;
    } catch (err) {
      SnackBarUtils.error(err.data.msg);
      return err;
    }
  }
);

const checkoutSlice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    initCheckout: (state: CheckoutState, action) => ({
      ...initialState,
      ...action.payload,
    }),
    setCheckout: (state: CheckoutState, actions: PayloadAction<CheckoutState>) => ({
      ...actions.payload,
    }),
    handleShippingAddress: (state: CheckoutState, action: PayloadAction<{ name: string; value: string | boolean }>) => {
      const { name, value } = action.payload;

      state.shippingAddress = {
        ...state.shippingAddress,
        [name]: value,
      };
    },
    setPostCode: (state: CheckoutState, action: PayloadAction<{ zipCode: string; address: string }>) => {
      state.shippingAddress = {
        ...state.shippingAddress,
        ...action.payload,
      };
    },
    handleChecked: (state: CheckoutState, action: PayloadAction<{ name: string; checked: boolean }>) => {
      const { name, checked } = action.payload;

      const updatedState = {
        ...state,
        [name]: checked,
      };
      return updatedState;
    },
    setUsePoint: (state, action) => {
      const usePoint = action.payload;
      state.usePoint = usePoint;
      state.payAmount = state.totalAmount - usePoint;
    },
    setShippingAddress: (state, action) => {
      state.shippingAddress = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getListShippingAddress.fulfilled, (state, action) => {
      const defaultAddress = action.payload.find((address) => address.defaultYn);

      if (defaultAddress) {
        state.shippingAddress = defaultAddress;
      }

      state.addressList = action.payload;
    });
    builder.addCase(deleteShippingAddress.fulfilled, (state, action) => {
      state.addressList = state.addressList.filter((address) => address.shippingAddressId !== action.payload);
      if (state.addressList.length === 0) {
        state.shippingAddress = initialState.shippingAddress;
      }
    });
    builder.addCase(saveShippingAddress.fulfilled, (state, action) => {
      getListShippingAddress();
    });
    builder.addCase(getLatestShippingAddress.fulfilled, (state, action) => {
      if (!action.payload.address) return;

      state.shippingAddress = {
        ...state.shippingAddress,
        shippingAddressId: '',
        shippingAddressNm: '최근 배송지',
        receiver: action.payload.receiver,
        phone: action.payload.phone,
        zipCode: action.payload.zipCode,
        address: action.payload.address,
        addressDetail: action.payload.addressDetail,
        defaultYn: false,
        shippingMemo: action.payload.shippingMemo,
      };
    });
  },
});

export const {
  initCheckout,
  setCheckout,
  handleShippingAddress,
  setPostCode,
  handleChecked,
  setUsePoint,
  setShippingAddress,
} = checkoutSlice.actions;

export default checkoutSlice.reducer;
