import { combineReducers } from '@reduxjs/toolkit';
import store from './store';
import xpnr from './xpnr';

const rootReducer = combineReducers({
  xpnr,
  store,
});

export default rootReducer;
