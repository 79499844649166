import { Theme, makeStyles, useMediaQuery } from '@material-ui/core';
import type { FC } from 'react';
import { Outlet } from 'react-router-dom';
import useSettings from 'src/hooks/useSettings';
import XpnrBottomNavigation from 'src/layout/XpnrBottomNavigation';
import InAppService from 'src/services/InAppService';
import TopBar from './TopBar';
import MobileTopBar from './MobileTopBar';
import Footer from './Footer';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    width: '100%',
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    width: '100%',
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    width: '100%',
  },
  content: {
    flex: '1 1 auto',
    width: '100%',
  },
}));

const MainLayout: FC = () => {
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const classes = useStyles();
  const { settings } = useSettings();

  return (
    <div className={classes.root}>
      {lgUp && <TopBar />}
      {!lgUp && <MobileTopBar />}
      <div className={classes.wrapper} style={settings.wrapperStyle}>
        <div className={classes.contentContainer}>
          <div id="mainlayout-content" className={classes.content}>
            <Outlet />
            {lgUp && <Footer />}
          </div>
        </div>
      </div>
      {!lgUp && InAppService.showBottomNavigation && <XpnrBottomNavigation />}
    </div>
  );
};

export default MainLayout;
