import { FC } from 'react';

const XpnrSplashScreen: FC = () => (
  <div id="xpnr-splash-screen">
    <div className="center">
      <div className="logo">
        <img width="250" src="assets/images/logos/xpanner-shop-logo.png" alt="logo" />
      </div>
      <div className="spinner-wrapper">
        <div className="spinner">
          <div className="inner">
            <div className="gap" />
            <div className="left">
              <div className="half-circle" />
            </div>
            <div className="right">
              <div className="half-circle" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default XpnrSplashScreen;
