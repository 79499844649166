import Axios from 'axios';
import {
  OrderCancelDTO,
  OrderCancelInfoDTO,
  OrderConfirmDTO,
  OrderDetailDTO,
  OrderDTO,
  OrderPageList,
  OrderPageParam,
  OrderParam,
  ReOrderProductVO,
  VbankAccountDTO,
} from '../types/StoreOrder';

class StoreOrderApi {
  async getListOrder(params: OrderPageParam): Promise<OrderPageList> {
    return new Promise<OrderPageList>((resolve, reject) => {
      Axios.get('/api/store/order/list', { params, useInterceptors: false })
        .then((response) => resolve(response.data.data))
        .catch((error) => {
          console.error('[StoreOrderApi]: ', error);
          reject(error.response);
        });
    });
  }

  async getOneOrder(params: OrderParam): Promise<OrderDTO> {
    return new Promise<OrderDTO>((resolve, reject) => {
      Axios.get(`/api/store/order/${params.productOrderId}`, { useInterceptors: false })
        .then((response) => resolve(response.data.data))
        .catch((error) => {
          console.error('[StoreOrderApi]: ', error);
          reject(error.response);
        });
    });
  }

  async updateOrderStatus(item: OrderConfirmDTO): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      Axios.put(`/api/store/order/detail`, item, { useInterceptors: false })
        .then((response) => resolve(response.data.data))
        .catch((error) => {
          console.error('[StoreOrderApi]: ', error);
          reject(error.response);
        });
    });
  }

  async getOneOrderDetail(params: { productOrderDetailId: string }): Promise<OrderDetailDTO> {
    return new Promise<OrderDetailDTO>((resolve, reject) => {
      Axios.get(`/api/store/order/detail`, { params, useInterceptors: false })
        .then((response) => resolve(response.data.data))
        .catch((error) => {
          console.error('[StoreOrderApi]: ', error);
          reject(error.response);
        });
    });
  }

  async cancelOrder(params: OrderCancelDTO): Promise<String> {
    return new Promise<String>((resolve, reject) => {
      Axios.post('/api/store/order/cancel', params, { useInterceptors: false })
        .then((response) => resolve(response.data.data))
        .catch((error) => {
          console.error('[StoreOrderApi]: ', error.response);
          reject(error.response);
        });
    });
  }

  async getOrderCancelInfo(orderCancelId: string): Promise<OrderCancelInfoDTO> {
    return new Promise<OrderCancelInfoDTO>((resolve, reject) => {
      Axios.get(`/api/store/order/cancel-info/${orderCancelId}`, { useInterceptors: false })
        .then((response) => resolve(response.data.data))
        .catch((error) => {
          console.error('[StoreOrderApi]: ', error);
          reject(error.response);
        });
    });
  }

  async returnRequest(productOrderDetailId: string): Promise<OrderCancelInfoDTO> {
    return new Promise<OrderCancelInfoDTO>((resolve, reject) => {
      Axios.post(`/api/store/order/return-request/${productOrderDetailId}`, { useInterceptors: false })
        .then((response) => resolve(response.data.data))
        .catch((error) => {
          console.error('[StoreOrderApi]: ', error);
          reject(error.response);
        });
    });
  }

  async getVbankAccount(productOrderId: string): Promise<VbankAccountDTO> {
    return new Promise<VbankAccountDTO>((resolve, reject) => {
      Axios.get(`/api/store/order/vbank-account/${productOrderId}`, { useInterceptors: false })
        .then((response) => resolve(response.data.data))
        .catch((error) => {
          console.error('[StoreOrderApi]: ', error);
          reject(error.response);
        });
    });
  }

  /* 재주문 상품 불러오기 api */
  async getListReOrderProductVo(productIds: String[]): Promise<ReOrderProductVO[]> {
    return new Promise((resolve, reject) => {
      Axios.post('/api/store/order/re-order', productIds, { useInterceptors: false })
        .then((response) => resolve(response.data.list))
        .catch((error) => {
          console.error('[StoreOrderApi]: ', error);
          reject(error.response);
        });
    });
  }
}

const storeOrderApi = new StoreOrderApi();

export default storeOrderApi;
