import { FC, ReactNode, useCallback, useEffect, useReducer } from 'react';
import { useNavigate } from 'react-router';
import * as cognito from 'src/services/cognito-service';
import { User } from 'src/types/user';
import { setAuthorizationHeader, removeAuthorizationHeader, setResponseInterceptor } from 'src/utils/axios';
import { authApi } from 'src/api/AuthApi';
import type { AuthState } from './AuthContext';
import { AuthContext, initialState } from './AuthContext';

enum ActionType {
  INITIALIZE = 'INITIALIZE',
  LOADING = 'LOADING',
  SIGN_OUT = 'SIGN_OUT',
}

type InitializeAction = {
  type: ActionType.INITIALIZE;
  payload: {
    isAuthenticated: boolean;
    user: User;
  };
};

type LoadingAction = {
  type: ActionType.LOADING;
};

type SignOutAction = {
  type: ActionType.SIGN_OUT;
};

type Action = InitializeAction | LoadingAction | SignOutAction;

type Handler = (state: AuthState, action: any) => AuthState;

const handlers: Record<ActionType, Handler> = {
  INITIALIZE: (state: AuthState, action: InitializeAction): AuthState => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOADING: (state: AuthState): AuthState => ({
    ...state,
    isInitialized: false,
  }),
  SIGN_OUT: (state: AuthState): AuthState => {
    removeAuthorizationHeader();
    return {
      ...state,
      isAuthenticated: false,
      isInitialized: true,
      user: initialState.user,
    };
  },
};

const reducer = (state: AuthState, action: Action): AuthState =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = useCallback(async (): Promise<void> => {
    dispatch({ type: ActionType.LOADING });

    try {
      const currentTokens = await cognito.getCurrentTokens();
      if (currentTokens) {
        setAuthorizationHeader('Bearer', currentTokens.accessToken);
        const user = await authApi.me();
        dispatch({
          type: ActionType.INITIALIZE,
          payload: {
            isAuthenticated: true,
            user,
          },
        });
      } else {
        dispatch({
          type: ActionType.INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: initialState.user,
          },
        });
      }
    } catch (e) {
      await cognito.signOut();
      dispatch({ type: ActionType.SIGN_OUT });
    }
  }, [dispatch]);

  const check = useCallback(async (): Promise<void> => {
    const { isInitialized, user } = state;
    if (!isInitialized) return;
    const isAuth = await cognito.checkTokens();

    dispatch({
      type: ActionType.INITIALIZE,
      payload: {
        isAuthenticated: isAuth,
        user,
      },
    });
  }, []);

  const refreshTokens = useCallback(async (): Promise<void> => {
    try {
      const token = await cognito.refreshTokens();
      setAuthorizationHeader('Bearer', token?.accessToken || '');
      initialize();
    } catch (e) {
      await cognito.signOut();
      dispatch({ type: ActionType.SIGN_OUT });
    }
  }, [dispatch]);

  const logout = useCallback(async (): Promise<void> => {
    await cognito.signOut();
    dispatch({ type: ActionType.SIGN_OUT });
  }, [dispatch]);

  const globalLogout = useCallback(async (): Promise<void> => {
    await cognito.globalSignOut();
    dispatch({ type: ActionType.SIGN_OUT });
  }, [dispatch]);

  useEffect(() => {
    setResponseInterceptor((path: string) => navigate(path));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    check();
  }, [dispatch]);

  return (
    <AuthContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        ...state,
        logout,
        globalLogout,
        refreshTokens,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
