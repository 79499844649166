import { Dialog } from '@material-ui/core';
import { FC } from 'react';
import { useDispatch, useSelector } from 'src/store';
import { closeDialog } from 'src/store/xpnr/dialogSlice';

const XpnrDialog: FC = () => {
  const dispatch = useDispatch();
  const state = useSelector(({ xpnr }) => xpnr.dialog.state);
  const options = useSelector(({ xpnr }) => xpnr.dialog.options);

  return (
    <Dialog open={state} onClose={() => dispatch(closeDialog())} aria-labelledby="xpnr-dialog-title" {...options} />
  );
};

export default XpnrDialog;
