import { CssBaseline, StylesProvider, ThemeProvider, jssPreset, makeStyles } from '@material-ui/core';
import { useLocation, useRoutes } from 'react-router-dom';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import koLocale from 'date-fns/locale/ko';
import { create } from 'jss';
import rtl from 'jss-rtl';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import ReactGA from 'react-ga4';
import DeliveryTrackingDialog from './components/DeliveryTrackingDialog';
import GlobalStyles from './components/GlobalStyles';
import KakaoPostcodeDialog from './components/KakaoPostcodeDialog';
import RouteChangeTracker from './components/RouterChangeTracker';
import XpnrDialog from './components/XpnrDialog';
import XpnrSplashScreen from './components/XpnrSplashScreen';
import { GA_CONFIG, THEMES } from './config';
import { AuthContext } from './contexts/AuthContext';
import { AuthProvider } from './contexts/AuthProvider';
import XpnrContext, { XpnrProvider } from './contexts/XpnrContext';
import useScrollReset from './hooks/useScrollReset';
import useSettings from './hooks/useSettings';
import routes from './routes';
import { createCustomTheme } from './theme';
import { SnackbarUtilsConfigurator } from './utils/SnackBarUtils';
import XpnrDateFnsUtils from './utils/XpnrDateFnsUtils';

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

// notistack 모바일 화면사이즈에서는 가운데정렬이 안됨.
const useStyles = makeStyles(() => ({
  snack: {
    '& .MuiCollapse-wrapperInner': {
      margin: 'auto',
    },
  },
}));

function App() {
  const classes = useStyles();

  const content = useRoutes(routes);
  const location = useLocation();
  const { settings } = useSettings();

  useScrollReset();

  const theme = createCustomTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: THEMES.LIGHT,
  });

  React.useEffect(() => {
    // prettier-ignore
    console.log(
      '   __ __ _____ _____ _____ _____ _____ _____ \n'
    + '  |  |  |  _  |  _  |   | |   | |   __| __  |\n'
    + '  |-   -|   __|     | | | | | | |   __|    -|\n'
    + '  |__|__|__|  |__|__|_|___|_|___|_____|__|__|\n'
    + '                            __\n'
    + "      __                   //\\\\`'-.___\n"
    + '     //\\\\  _______        //  \\\\  _(=()__\n'
    + '    //  \\\\//~//.--|       Y    \\\\//~//.--|\n'
    + '    Y   /\\\\~~//_  |       :    /\\\\~~//_  |\n'
    + '   _L  |_((_|___L_|      _L   |_((_|___L_|\n'
    + '  (/\\)(____(_______)    (/\\) (____(_______)  \n'
    + '\n'
    + '                              _ _\n'
    + '                        _ ___L_|_\\      ____\n'
    + "                       L_|_____|__]   ,' = =`---.\n"
    + "                        (_(_)  (_)    `(_)--(_)-'\n"
    + '                        \n'
    + '--------------------------------------------------\n'
    + '   🔧🔧🔧  Hello World  XPANNER STORE  🔧🔧🔧 \n'
    + '--------------------------------------------------'
    );

    ReactGA.initialize(GA_CONFIG.MeasurementId);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <StylesProvider jss={jss}>
        <MuiPickersUtilsProvider utils={XpnrDateFnsUtils} locale={koLocale}>
          <XpnrProvider>
            <XpnrContext.Consumer>
              {({ isInAppInit }) =>
                !isInAppInit ? (
                  <XpnrSplashScreen />
                ) : (
                  <AuthProvider>
                    <DeliveryTrackingDialog />
                    <GlobalStyles />
                    <CssBaseline />
                    <SnackbarProvider
                      dense
                      maxSnack={3}
                      autoHideDuration={2500}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                      classes={{ containerRoot: classes.snack }}
                    >
                      <SnackbarUtilsConfigurator />
                      <XpnrDialog />
                      <KakaoPostcodeDialog />
                      <AuthContext.Consumer>
                        {({ isInitialized }) =>
                          !isInitialized ? (
                            <XpnrSplashScreen />
                          ) : (
                            <>
                              {content}
                              <RouteChangeTracker />
                            </>
                          )
                        }
                      </AuthContext.Consumer>
                    </SnackbarProvider>
                  </AuthProvider>
                )
              }
            </XpnrContext.Consumer>
          </XpnrProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </ThemeProvider>
  );
}

export default App;
