import { createSlice } from '@reduxjs/toolkit';

export interface ShippingAddrMngProps {
  open: boolean;
}

interface ShippingAddrMngState {
  props: ShippingAddrMngProps;
}

const initialState: ShippingAddrMngState = {
  props: {
    open: false,
  },
};

const ShippingAddrMngSlice = createSlice({
  name: 'shoppingAddrMng',
  initialState,
  reducers: {
    openShippingAddrMngDialog: (state) => {
      state.props.open = true;
    },
    closeShippingAddrMngDialog: (state) => {
      state.props.open = false;
    },
  },
});

export const { openShippingAddrMngDialog, closeShippingAddrMngDialog } = ShippingAddrMngSlice.actions;

export default ShippingAddrMngSlice.reducer;
