import { createStyles, makeStyles } from '@material-ui/core';

const styles = createStyles({
  '@global': {
    '*': {
      boxSizing: 'border-box',
      margin: 0,
      padding: 0,
    },
    html: {
      '-webkit-font-smoothing': 'antialiased',
      '-moz-osx-font-smoothing': 'grayscale',
      height: '100%',
      width: '100%',
    },
    body: {
      height: '100%',
      width: '100%',
    },
    '#root': {
      height: '100%',
      width: '100%',
    },
    'input::placeholder': {
      color: '#D3D3D3 !important',
    },
    'textarea::placeholder': {
      color: '#D3D3D3 !important',
    },
  },
});
const useStyles = makeStyles(() => styles);

const GlobalStyles = () => {
  useStyles();
  return null;
};

export default GlobalStyles;
