import Axios from 'axios';
import { RcmndCtgryDTO } from 'src/types/SalesProdItem';
import { SalesProductDetailDTO } from 'src/types/SalesProduct';
import { PageSalesProductListDTO, SalesProductListParam } from 'src/types/SalesProducts';

declare module 'axios' {
  export interface AxiosRequestConfig {
    useInterceptors?: boolean;
  }
}

class StoreProductApi {
  // api/store/products/product-category/{productCategoryId}
  async getPageSalesProductListDTObyprodCtgId(param: SalesProductListParam): Promise<PageSalesProductListDTO> {
    // productCategoryId 만 빼고 나머지는 otherParam 파라미터로 넘김
    const { productCategoryId, ...otherParam } = param;

    return new Promise<PageSalesProductListDTO>((resolve, reject) => {
      Axios.get(`api/store/products/product-category/${productCategoryId}`, {
        params: otherParam,
        useInterceptors: false,
      })
        .then((response) => resolve(response.data.data))
        .catch((error) => {
          console.error('[StoreProductApi]: ', error);
          reject(error.response);
        });
    });
  }

  // api/store/products/recommend-category/{recommendCategoryId}
  async getRcmndCtgryDTObyRcmndctgryId(recommendCategoryId: String): Promise<RcmndCtgryDTO> {
    return new Promise<RcmndCtgryDTO>((resolve, reject) => {
      Axios.get(`api/store/products/recommend-category/${recommendCategoryId}`, { useInterceptors: false })
        .then((response) => resolve(response.data.data))
        .catch((error) => {
          console.error('[StoreProductApi]: ', error);
          reject(error.response);
        });
    });
  }

  // api/store/product/{salesProductId}
  async getSalesProductDetailDTO(salesProductId: string): Promise<SalesProductDetailDTO> {
    return new Promise<SalesProductDetailDTO>((resolve, reject) => {
      Axios.get(`api/store/product/${salesProductId}`, { useInterceptors: false })
        .then((response) => resolve(response.data.data))
        .catch((error) => {
          console.error('[StoreProductApi]: ', error);
          reject(error.response);
        });
    });
  }
}

export const storeProductApi = new StoreProductApi();
