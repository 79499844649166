import DateFnsUtils from '@date-io/date-fns';
import koLocale from 'date-fns/locale/ko';
import format from 'date-fns/format';

export default class XpnrDateFnsUtils extends DateFnsUtils {
  locale = koLocale;

  getCalendarHeaderText(date) {
    return format(date, 'yyyy.MM', { locale: this.locale });
  }
}
