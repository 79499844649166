import InAppService from 'src/services/InAppService';
import { SalesProdPriceItem } from 'src/types/SalesProdItem';

class XpnrStoreUtils {
  static getCalcDisplayPrice(item: SalesProdPriceItem) {
    if (item.discountRate) {
      const price = item.displayPrice - this.getDiscountPrice(item);
      return price;
    }
    if (item.discountAmount) {
      const price = item.displayPrice - this.getDiscountPrice(item);
      return price;
    }
    return item.displayPrice;
  }

  static getDiscountPrice(item: SalesProdPriceItem) {
    if (item.discountRate) {
      const price = item.displayPrice * item.discountRate;
      return Math.floor(price / 10) * 10; // 금액 원단위 절삭처리
    }
    if (item.discountAmount) {
      return Math.floor(item.discountAmount / 10) * 10; // 금액 원단위 절삭처리
    }
    return NaN;
  }

  static getHeightXpnrBottomNavi(): number {
    return InAppService.isFlutterApp ? 0 : (/iPhone|iPad|iPod/i.test(navigator.userAgent) ? 15 : 0) + 56;
  }
}

export default XpnrStoreUtils;
