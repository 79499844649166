import merge from 'lodash/merge';
import { responsiveFontSizes, unstable_createMuiStrictModeTheme } from '@material-ui/core/styles';
import { colors, Direction, Theme, ThemeOptions } from '@material-ui/core';
import { softShadows, strongShadows } from './shadows';
import typography from './typography';
import { THEMES } from '../config';

interface ThemeConfig {
  direction?: Direction;
  responsiveFontSizes?: boolean;
  roundedCorners?: boolean;
  theme?: string;
}

const baseOptions: ThemeOptions = {
  direction: 'ltr',
  typography,
  overrides: {
    MuiCssBaseline: {
      '@global': {
        // override the pseudo-classes
        '.Mui-disabled': { background: '#F8F8F8' },
      },
    },
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: 'hidden',
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32,
      },
    },
    MuiChip: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.075)',
      },
    },
  },
};

const themesOptions: Record<string, ThemeOptions> = {
  [THEMES.LIGHT]: {
    overrides: {
      MuiInputBase: {
        input: {
          '&::placeholder': {
            opacity: 1,
            color: colors.blueGrey[600],
          },
        },
      },
    },
    palette: {
      action: {
        active: colors.blueGrey[600],
      },
      background: {
        default: colors.common.white,
        paper: colors.common.white,
        // dark: '#f4f6f8',
      },
      primary: {
        main: '#004727',
      },
      secondary: {
        main: '#66E2A0',
      },
      text: {
        primary: '#1E1E22',
        secondary: '#999999',
      },
    },
    shadows: softShadows,
  },
  [THEMES.ONE_DARK]: {
    palette: {
      type: 'dark',
      action: {
        active: 'rgba(255, 255, 255, 0.54)',
        hover: 'rgba(255, 255, 255, 0.04)',
        selected: 'rgba(255, 255, 255, 0.08)',
        disabled: 'rgba(255, 255, 255, 0.26)',
        disabledBackground: 'rgba(255, 255, 255, 0.12)',
        focus: 'rgba(255, 255, 255, 0.12)',
      },
      background: {
        default: '#282C34',
        paper: '#282C34',
        // dark: '#1c2025',
      },
      primary: {
        main: '#2196f3',
      },
      secondary: {
        main: '#8a85ff',
      },
      text: {
        primary: '#e6e5e8',
        secondary: '#adb0bb',
      },
    },
    shadows: strongShadows,
  },
};

export const createCustomTheme = (config: ThemeConfig = {}): Theme => {
  let themeOptions = themesOptions[config.theme != null ? config.theme : THEMES.LIGHT];

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    themeOptions = themesOptions[THEMES.LIGHT];
  }

  let theme = unstable_createMuiStrictModeTheme(
    merge({}, baseOptions, themeOptions, {
      direction: config.direction,
    })
  );

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
