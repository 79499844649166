import { lazy, Suspense } from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import { AuthGuard, GuestGuard } from './components/authentication';
import LoadingScreen from './components/LoadingScreen';
import MainLayout from './layout/MainLayout';

const Loadable = (Component: any) => (props: any) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

const Bridge = Loadable(lazy(() => import('./pages/home/BridgePage')));

const StoreMain = Loadable(lazy(() => import('./pages/store/main/StoreMainPage')));
const StoreSearch = Loadable(lazy(() => import('./pages/store/main/StoreSearchPage')));
const NotFound = Loadable(lazy(() => import('./pages/errors/404')));
const ServerError = Loadable(lazy(() => import('./pages/errors/500')));
const Authorization = Loadable(lazy(() => import('./pages/errors/401')));
const Forbidden = Loadable(lazy(() => import('./pages/errors/403')));
const StoreProducts = Loadable(lazy(() => import('./pages/store/products/StoreProductsPage')));
const RcmndCtgryProductsPage = Loadable(lazy(() => import('./pages/store/products/RcmndCtgryProductsPage')));
const ProductsWishListPage = Loadable(lazy(() => import('./pages/store/products/ProductsWishListPage')));
const StoreProductDetail = Loadable(lazy(() => import('./pages/store/product/StoreProductDetailPage')));
const ShoppingBasket = Loadable(lazy(() => import('./pages/store/shopping-basket/ShoppingBasketPage')));
const CheckOut = Loadable(lazy(() => import('./pages/store/check-out/CheckoutPage')));
const CheckoutSuccess = Loadable(lazy(() => import('./pages/store/check-out/CheckoutSuccessPage')));
const CheckoutFail = Loadable(lazy(() => import('./pages/store/check-out/CheckoutFailPage')));
const StoreOrder = Loadable(lazy(() => import('./pages/store/order/StoreOrderPage')));
const StoreOrderDetail = Loadable(lazy(() => import('./pages/store/order/StoreOrderDetailPage')));
const StoreOrderCancel = Loadable(lazy(() => import('./pages/store/order-cancel/StoreOrderCancelPage')));
const StoreOrderCancelSuccess = Loadable(lazy(() => import('./pages/store/order-cancel/StoreOrderCancelSuccessPage')));
const StoreReviewPage = Loadable(lazy(() => import('./pages/store/review/StoreReviewPage')));
const GuestPage = Loadable(lazy(() => import('./pages/store/GuestPage')));

// Projects pages
const routes: RouteObject[] = [
  {
    path: '/bridge',
    element: <Bridge />,
  },
  // Store path
  {
    path: 'store',
    element: <MainLayout />,
    children: [
      {
        path: 'main',
        element: <StoreMain />,
      },
      {
        path: 'search',
        element: <StoreSearch />,
      },
      {
        path: 'search/:keyword',
        element: <StoreSearch />,
      },
      {
        path: 'order-cancel/:productOrderId',
        element: <StoreOrderCancel />,
      },
      {
        path: 'order-cancel-success/:orderCancelId',
        element: <StoreOrderCancelSuccess />,
      },
      {
        path: 'products',
        element: <Navigate to="/store/products/product-category" replace />,
      },
      {
        path: 'products/product-category',
        element: <StoreProducts />,
      },
      {
        path: 'products/product-category/:salesProductId',
        element: <StoreProducts />,
      },
      {
        path: 'products/recommend-category/:recommendCategoryId',
        element: <RcmndCtgryProductsPage />,
      },
      {
        path: 'products/product-wish',
        element: (
          <AuthGuard>
            <ProductsWishListPage />
          </AuthGuard>
        ),
      },
      {
        path: 'product',
        element: <Navigate to="/store/products/product-category" replace />,
      },
      {
        path: 'product/:salesProductId',
        element: <StoreProductDetail />,
      },
      {
        path: 'order',
        element: (
          <AuthGuard>
            <StoreOrder />
          </AuthGuard>
        ),
      },
      {
        path: 'order/detail/:productOrderId',
        element: (
          <AuthGuard>
            <StoreOrderDetail />
          </AuthGuard>
        ),
      },
      {
        path: 'shopping-basket',
        element: (
          <AuthGuard>
            <ShoppingBasket />
          </AuthGuard>
        ),
      },
      {
        path: 'check-out',
        element: (
          <AuthGuard>
            <CheckOut />
          </AuthGuard>
        ),
      },
      {
        path: 'check-out-success/:productOrderId',
        element: <CheckoutSuccess />,
      },
      {
        path: 'check-out-fail',
        element: <CheckoutFail />,
      },
      {
        path: 'review/:productOrderDetailId',
        element: <StoreReviewPage />,
      },
      {
        path: 'guest',
        element: (
          <GuestGuard>
            <GuestPage />
          </GuestGuard>
        ),
      },
    ],
  },
  // Common
  {
    path: '/',
    children: [
      {
        path: '/',
        element: <Navigate to="/store/main" replace />,
      },
      {
        path: '404',
        element: <NotFound />,
      },
      {
        path: '401',
        element: <Authorization />,
      },
      {
        path: '403',
        element: <Forbidden />,
      },
      {
        path: '500',
        element: <ServerError />,
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
];

export default routes;
