import Axios from 'axios';
import { CommonResponse, ErrorResponse, SingleResponse } from 'src/types/response';
import { SalesProdListItem } from 'src/types/SalesProdItem';

declare module 'axios' {
  export interface AxiosRequestConfig {
    useInterceptors?: boolean;
  }
}

class ProductWishApi {
  async getListProductWish(): Promise<SalesProdListItem[]> {
    return new Promise<SalesProdListItem[]>((resolve, reject) => {
      Axios.get(`api/store/product-wish`, { useInterceptors: false })
        .then((response) => resolve(response.data.list))
        .catch((error) => {
          console.error('[ProductWishApi]: ', error);
          reject(error.response);
        });
    });
  }

  async deleteProductWish(salesProductIds: string[]): Promise<CommonResponse | ErrorResponse> {
    return new Promise((resolve, reject) => {
      Axios.delete(`/api/store/product-wish/${salesProductIds}`, { useInterceptors: false })
        .then((response) => resolve(response.data))
        .catch((error) => {
          console.error('[ProductWishApi]: ', error);
          reject(error.response);
        });
    });
  }

  async saveProductWish(salesProductId: string): Promise<SingleResponse<number> | ErrorResponse> {
    return new Promise((resolve, reject) => {
      Axios.post(`/api/store/product-wish/${salesProductId}`, { useInterceptors: false })
        .then((response) => resolve(response.data.data))
        .catch((error) => {
          console.error('[ProductWishApi]: ', error);
          reject(error.response);
        });
    });
  }
}

export const productWishApi = new ProductWishApi();
