import { AppBar, Avatar, Box, Container, IconButton, Link, Toolbar, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { type FC } from 'react';
import { Bell as BellIcon } from 'react-feather';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Logo from 'src/components/Logo';
import ShoppingCartButton from 'src/components/ShoppingCartButton';
import { URI_CONFIG } from 'src/config';
import { useAuth } from 'src/hooks/useAuth';
import XpnrUtils from 'src/utils/XpnrUtils';
import Search from './Search';

interface TopBarProps {
  className?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    boxShadow: 'none',
  },
  toolbarTop: {
    maxWidth: '1040px',
    height: '48px',
    margin: '0 auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: 50,
  },
  toolbarTopLink: {
    fontSize: 16,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  toolbar: {
    maxWidth: '1040px',
    margin: '0 auto',
    padding: 0,
    marginTop: 20,
    marginBottom: 28,
    height: 48,
    minHeight: 'auto',
  },
  logo: {
    marginRight: 52,
    verticalAlign: 'bottom',
  },
  link: {
    fontSize: 24,
    fontWeight: 700,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'none',
    },
    '& + &': {
      marginLeft: 32,
    },
  },
  linkActive: {
    color: '#0F7547',
  },
  divider: {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  avatar: {
    height: 30,
    width: 30,
  },
}));

const TopBar: FC<TopBarProps> = ({ className, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { isAuthenticated, user } = useAuth();

  const goNotiPage = () => XpnrUtils.goInAppChckNNotiPage(navigate);

  // finder_mo 이동
  const goFinderMoSite = () => {
    window.location.href = URI_CONFIG.finder_mo_uri;
  };

  // Rent_worker 이동
  const goRentWorkerSite = () => {
    window.location.href = URI_CONFIG.rent_worker_uri;
  };

  // Account 이동
  const goAccountSite = () => {
    const returnTo = `${URI_CONFIG.mainUrl}${window.location.pathname}`;
    const searchParams = new URLSearchParams({ redirect_uri: returnTo }).toString();
    const href = `${URI_CONFIG.loginUrl}?${searchParams}`;
    window.location.assign(href);
  };

  // Account mypage 이동
  const goAccountMypage = () => {
    window.location.href = URI_CONFIG.mypageUrl;
  };

  return (
    <AppBar
      className={clsx(classes.root, className)}
      color="default"
      {...rest}
      style={{ borderBottom: '1px solid #D3D3D3' }}
    >
      <Container maxWidth="lg" style={{ padding: 0 }}>
        <Box className={classes.toolbarTop}>
          {!isAuthenticated && (
            <Link className={classes.toolbarTopLink} color="textPrimary" onClick={goAccountSite}>
              로그인/회원가입
            </Link>
          )}

          {isAuthenticated && (
            <>
              <Box ml={2}>
                <Link className={classes.link} color="textPrimary" onClick={goAccountMypage}>
                  <Avatar
                    alt="User"
                    className={classes.avatar}
                    src={user.profileImage && XpnrUtils.getAttachURL(user.profileImage)}
                  />
                </Link>
              </Box>
              <Box ml={2}>
                <IconButton aria-label="bell" onClick={goNotiPage}>
                  <BellIcon />
                </IconButton>
              </Box>
            </>
          )}
        </Box>

        <Toolbar className={classes.toolbar}>
          <RouterLink to="/">
            <Logo className={classes.logo} />
          </RouterLink>

          <Link
            className={clsx(classes.link, classes.linkActive)}
            color="textPrimary"
            component={RouterLink}
            to="/"
            underline="none"
            variant="body2"
          >
            스토어
          </Link>

          <Link className={classes.link} color="textPrimary" onClick={goRentWorkerSite}>
            렌트
          </Link>

          <Link className={classes.link} color="textPrimary" onClick={goFinderMoSite}>
            파인더
          </Link>

          <Box flexGrow={1} />

          <Box ml={2}>
            <Search />
          </Box>
          <Box ml={2}>
            <ShoppingCartButton />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
};

export default TopBar;
