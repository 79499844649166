import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import useSettings from 'src/hooks/useSettings';

const useScrollReset = (): null => {
  const location = useLocation();
  const { resetScrollPostion } = useSettings();

  useEffect(() => {
    // console.log('useScrollReset', location.pathname);
    window.scrollTo(0, 0);
    resetScrollPostion();
  }, [location.pathname]);

  return null;
};

export default useScrollReset;
