import { Dialog, DialogTitle, IconButton, makeStyles, Slide } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import CloseIcon from '@material-ui/icons/Close';
import React, { FC, useEffect } from 'react';
import DaumPostcode from 'react-daum-postcode';
import type { Address } from 'react-daum-postcode/lib/loadPostcode';
import {
  closeKakaoPostcodeDialog,
  KakaoPostcodeData,
  KakaoPostcodeProps,
  setData,
} from 'src/store/xpnr/kakaoPostcodeSlice';
import { useDispatch, useSelector } from '../../store/index';

// 참고 https://enfanthoon.tistory.com/165
declare global {
  interface Window {
    kakao: any;
  }
}

const useStyles = makeStyles((theme) => ({
  closeIconBtn: {
    position: 'absolute',
    right: 8,
    top: 4,
    color: theme.palette.grey[500],
  },
  iframeWrap: {
    position: 'fixed',
    width: '100%',
    height: 'calc(100% - 64px)',
  },
}));

const Transition = React.forwardRef(
  (props: TransitionProps & { children?: React.ReactElement }, ref: React.Ref<unknown>) => (
    <Slide direction="up" ref={ref} {...props} />
  )
);

const KakaoPostcodeDialog: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { open }: KakaoPostcodeProps = useSelector(({ xpnr }) => xpnr.kakaoPostcode.props);

  useEffect(() => {
    // kakao api 로딩
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://dapi.kakao.com/v2/maps/sdk.js?appkey=${process.env.REACT_APP_KAKAO_API_APPKEY}&autoload=false&libraries=services`;
    document.head.appendChild(script);

    script.onload = () => {
      window.kakao.maps.load();
    };
  }, []);

  const closeDialog = () => {
    dispatch(closeKakaoPostcodeDialog());
  };

  const handleDaumPostcodeComplete = (data: Address) => {
    if (window.kakao == null) {
      dispatch(
        setData({
          address: data.address,
          latitude: 0,
          longitude: 0,
          zipCode: data.zonecode,
        })
      );
      dispatch(closeKakaoPostcodeDialog());
      return;
    }

    // 주소를 좌표로 변환
    const geocoder = new window.kakao.maps.services.Geocoder();

    geocoder.addressSearch(data.address, async (result: any, status: any) => {
      // 정상적으로 검색이 완료됐으면
      if (status === window.kakao.maps.services.Status.OK) {
        // const coords = new window.kakao.maps.LatLng(result[0].y, result[0].x);
        // 우편번호
        const zipCode = result[0].road_address.zone_no;
        const kpData: KakaoPostcodeData = {
          address: data.address,
          latitude: result[0].y,
          longitude: result[0].x,
          zipCode,
        };
        dispatch(setData(kpData));
        dispatch(closeKakaoPostcodeDialog());
      }
    });
  };

  return (
    <>
      <Dialog
        aria-labelledby="kakao-postcode-dialog-title"
        fullScreen
        open={open}
        onClose={closeDialog}
        TransitionComponent={Transition}
      >
        <IconButton aria-label="close" onClick={closeDialog} className={classes.closeIconBtn}>
          <CloseIcon />
        </IconButton>
        <DialogTitle>주소 검색</DialogTitle>
        <DaumPostcode style={{ height: '100%' }} onComplete={handleDaumPostcodeComplete} hideMapBtn hideEngBtn />
      </Dialog>
    </>
  );
};

export default KakaoPostcodeDialog;
