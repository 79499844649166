import Axios, { AxiosResponse } from 'axios';
import { CommonResponse, ErrorResponse } from 'src/types/response';

declare module 'axios' {
  export interface AxiosRequestConfig {
    useInterceptors?: boolean;
  }
}

class ProductRestoreApi {
  async request(productInfoId: string): Promise<AxiosResponse<CommonResponse, ErrorResponse>> {
    return new Promise((resolve, reject) => {
      Axios.post(`/api/store/product/${productInfoId}/restock`, { useInterceptors: false })
        .then((response) => resolve(response))
        .catch((error) => {
          console.error('[ProductRestoreApi]: ', error);
          reject(error.response);
        });
    });
  }
}

const productRestoreApi = new ProductRestoreApi();

export default productRestoreApi;
