import { CircularProgress } from '@material-ui/core';
import { FC, memo } from 'react';

interface LoadingCircularProgressProps {
  className?: string;
}

const LoadingCircularProgress: FC<LoadingCircularProgressProps> = (props) => {
  const { className } = props;

  return (
    <div className={className}>
      <CircularProgress />
    </div>
  );
};

export default memo(LoadingCircularProgress);
