import axios from 'axios';
import _ from 'lodash';
import { XpnrCodeState } from 'src/contexts/XpnrContext';
import { CodeDTO, CodeParam } from 'src/types/code';

declare module 'axios' {
  export interface AxiosRequestConfig {
    useInterceptors?: boolean;
  }
}

class CodeApi {
  // GET /api/v2/cmm/code/{firstTypeCode}/{secondTypeCode}
  list(firstTypeCode: string, secondTypeCode: string): Promise<CodeDTO[]> {
    return new Promise<CodeDTO[]>((resolve, reject) => {
      axios
        .get(`/api/v2/cmm/code/${firstTypeCode}/${secondTypeCode}`, { useInterceptors: false })
        .then((response) => resolve(response.data.list))
        .catch((error) => {
          console.error('[StoreCodeApi]: ', error);
          reject(error.response);
        });
    });
  }

  // GET /api/v2/cmm/code/get
  // paramCode : [{ code1: '001', code2: '001' }]
  getMultiCodeList(paramCode: CodeParam[]): Promise<XpnrCodeState> {
    const queryString: string = _.map(paramCode, (p) => `firstTypeCode=${p.code1}&secondTypeCode=${p.code2}`).join('&');
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v2/cmm/code/get?${queryString}`, { useInterceptors: false })
        .then((response) => resolve(response.data.data))
        .catch((error) => {
          console.error('[StoreCodeApi]: ', error);
          reject(error.response);
        });
    });
  }

  // GET /api/v2/cmm/code/machine/model
  getMchModelsAll = () =>
    new Promise<CodeDTO[]>((resolve, reject) => {
      axios
        .get(`/api/v2/cmm/code/machine/model`, { params: 'finder', useInterceptors: false })
        .then((response) => resolve(response.data.list))
        .catch((error) => {
          console.error('[StoreCodeApi]: ', error);
          reject(error.response);
        });
    });
}

const codeApi = new CodeApi();

export default codeApi;
