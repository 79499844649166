import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import productRestoreApi from 'src/api/ProductRestoreApi';
import { productWishApi } from 'src/api/ProductWishApi';
import { storeProductApi } from 'src/api/StoreProductApi';
import { SalesProductDTO, SalesProductDetailDTO } from 'src/types/SalesProduct';
import { ErrorResponse } from 'src/types/response';
import XpnrStoreUtils from 'src/utils/XpnrStoreUtils';
import XpnrUtils from 'src/utils/XpnrUtils';

interface SalesProductDetailState {
  loading: boolean;

  dto: SalesProductDetailDTO;
  selectedProductId?: string;

  errorRes?: ErrorResponse | null;
  buyBarStep: number;
  buyStepYn: boolean;
  isBackdrop: boolean;

  totalPrice: number;
}

const initialState: SalesProductDetailState = {
  loading: false,

  dto: {
    salesProductId: '',
    salesProductNm: 'is not Product', // 여기는 영문 약어명이 나옴.
    salesProductDesc: '제품없음',
    salesProductBodyContent: '',
    brandNm: '브랜드',
    brandId: '',

    attachGroupList: [],

    displayPrice: 0,
    discountRate: undefined,
    discountAmount: undefined,
    displayShippingFee: 3000,

    products: [],

    displaySubtitle: '',
    genuineYn: false,

    totalRating: 0,

    wishCnt: 0,
    wishYn: false,
    reviews: [],

    warringText: '',
  },
  buyBarStep: 1,
  buyStepYn: false,
  isBackdrop: false,

  selectedProductId: undefined,
  totalPrice: 0,
};

// 제품정보 불러오기 getSalesProductDetailDTO
//     storeProductApi.getSalesProductDetailDTO()
export const getSalesProductDetailDTO = createAsyncThunk<SalesProductDetailDTO, { salesProductId: string }>(
  'product/getSalesProductDetailDTO',
  async ({ salesProductId }, { rejectWithValue }) => {
    try {
      if (!salesProductId) {
        throw new Error('salesProductId Parameter is undefined!!');
      }
      const response = await storeProductApi.getSalesProductDetailDTO(salesProductId);
      return response;
    } catch (err) {
      return XpnrUtils.catchThunkErrorHandler(err, rejectWithValue);
    }
  }
);

// 찜하기 addWish
export const saveProductWish = createAsyncThunk<number, { salesProductId: string }>(
  'product/saveProductWish',
  async ({ salesProductId }, { rejectWithValue }) => {
    try {
      if (!salesProductId) {
        throw new Error('salesProductId Parameter is undefined!!');
      }
      const response = await productWishApi.saveProductWish(salesProductId);
      return response;
    } catch (err) {
      return XpnrUtils.catchThunkErrorHandler(err, rejectWithValue);
    }
  }
);

// 재입고 요청
export const reqProductRestock = createAsyncThunk<string, { productInfoId: string }>(
  'product/requestProductRestock',
  async ({ productInfoId }, { rejectWithValue }) => {
    try {
      if (!productInfoId) {
        throw new Error('productInfoId Parameter is undefined!!');
      }
      await productRestoreApi.request(productInfoId);
      return productInfoId;
    } catch (err) {
      return XpnrUtils.catchThunkErrorHandler(err, rejectWithValue);
    }
  }
);

const emptyState = (state: SalesProductDetailState) => {
  state.dto = initialState.dto;
  state.selectedProductId = undefined;
  state.buyStepYn = false;
};
const calcTotalPrice = (state: SalesProductDetailState) => {
  let totalPrice: number = 0;
  state.dto.products.forEach((prod) => {
    totalPrice += XpnrStoreUtils.getCalcDisplayPrice(prod) * prod.orderCnt;
  });
  state.totalPrice = totalPrice;
};

const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    setSelectedProductId: (state, action) => {
      state.selectedProductId = action.payload;
    },
    openBuyProductPopup: (state, action) => {
      const open: boolean = action.payload;
      state.isBackdrop = open;

      if (!open) {
        state.buyBarStep = 1;
      }
    },
    changedBuyStep: (state, action) => {
      const newStep: number = action.payload;
      state.buyBarStep = newStep;
      if (newStep === 2 || newStep === 3) {
        state.isBackdrop = true;
        state.dto.products.forEach((prod) => {
          prod.orderCnt = state.selectedProductId === prod.salesProductId ? 1 : 0;
        });
        calcTotalPrice(state);
      } else {
        state.isBackdrop = false;
        state.dto.products.forEach((prod) => {
          prod.orderCnt = 0;
        });
      }
    },
    changeProductOrderCnt: (state, action) => {
      const { salesProductId, orderCnt } = action.payload;
      state.dto.products.forEach((prod) => {
        if (prod.salesProductId === salesProductId) {
          prod.orderCnt = orderCnt;
        }
      });
      calcTotalPrice(state);
    },
    initErrorRes: (state) => {
      state.errorRes = null;
    },
    initWarningTxt: (state) => {
      state.dto.warringText = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSalesProductDetailDTO.pending, (state) => {
      // console.log('getSalesProductDetailDTO.pending');
      emptyState(state);
      state.loading = true;
    });
    builder.addCase(getSalesProductDetailDTO.fulfilled, (state, action) => {
      const dto = action.payload;
      dto.products.forEach((product) => {
        product.orderCnt = 0;
      });

      state.dto = dto;

      state.selectedProductId = dto.products.length === 1 ? dto.products[0].salesProductId : undefined;

      calcTotalPrice(state);

      state.buyBarStep = 1;
      state.isBackdrop = false;
      state.loading = false;
    });
    builder.addCase(getSalesProductDetailDTO.rejected, (state, action) => {
      state.errorRes = action.payload as ErrorResponse;
      state.loading = false;
    });
    builder.addCase(saveProductWish.fulfilled, (state, action) => {
      const wishCnt = action.payload;
      state.dto.wishCnt = wishCnt;
      state.dto.wishYn = !state.dto.wishYn;
    });
    builder.addCase(reqProductRestock.fulfilled, (state, action) => {
      const productInfoId = action.payload;
      const product: SalesProductDTO | undefined = state.dto.products.find((v) => v.salesProductId === productInfoId);

      if (product) product.restoreReqYn = !product.restoreReqYn;
    });
  },
});

export const {
  setSelectedProductId,
  openBuyProductPopup,
  changedBuyStep,
  changeProductOrderCnt,
  initErrorRes,
  initWarningTxt,
} = productSlice.actions;

export default productSlice.reducer;
