import type { Action } from '@reduxjs/toolkit';
import { configureStore } from '@reduxjs/toolkit';
import type { TypedUseSelectorHook } from 'react-redux';
import { useDispatch as useReduxDispatch, useSelector as useReduxSelector } from 'react-redux';
import type { ThunkAction } from 'redux-thunk';
import rootReducer from './rootReducer';

const middlewares: any[] = [];

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat(middlewares),
  devTools: process.env.REACT_APP_ENABLE_REDUX_DEV_TOOLS === 'true',
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;

export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;

export const useDispatch = () => useReduxDispatch<AppDispatch>();

//  TODO dynamically inject reducers on demand 했으면 좋겠구만...
//    https://stackoverflow.com/questions/69163257/replacereducer-on-reduxjs-toolkit
//    https://stackoverflow.com/questions/65391970/typescript-root-reducer-type-while-using-dynamic-reducers
//       https://www.matthewgerstman.com/tech/redux-code-split-typecheck/

export default store;
