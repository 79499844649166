import { createSlice } from '@reduxjs/toolkit';

interface DialogState {
  state: boolean;
  options: any;
}

const initialState: DialogState = {
  state: false,
  options: {
    children: 'Hi',
  },
};

const dialogSlice = createSlice({
  name: 'dialog',
  initialState,
  reducers: {
    openDialog: (state, action) => {
      state.state = true;
      state.options = action.payload;
    },
    closeDialog: (state) => {
      state.state = false;
    },
  },
});

export const { openDialog, closeDialog } = dialogSlice.actions;

export default dialogSlice.reducer;
