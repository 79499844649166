import { createContext } from 'react';
import type { User } from 'src/types/user';

const initUserData: User = {
  id: '',
  usrId: '',
  usrNm: '방문자',
  nickNm: 'guest',
  phone: '00000000000',
  email: 'guest@xpanner.com',
  userType: '',
};

export interface AuthState {
  isAuthenticated: boolean;
  isInitialized: boolean;
  user: User;
}

export const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: initUserData,
};

export interface AuthContextType extends AuthState {
  logout: () => Promise<void>;
  globalLogout: () => Promise<void>;
  refreshTokens: () => Promise<void>;
}

export const AuthContext = createContext<AuthContextType>({
  ...initialState,
  logout: () => Promise.resolve(),
  globalLogout: () => Promise.resolve(),
  refreshTokens: () => Promise.resolve(),
});
