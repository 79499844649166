import axios from 'axios';
import { ErrorResponse, SingleResponse } from 'src/types/response';
import { ShippingAddressDTO } from 'src/types/shippingAddress';

declare module 'axios' {
  export interface AxiosRequestConfig {
    useInterceptors?: boolean;
  }
}

class ShippingAddressApi {
  // 배송지 목록 조회
  async getListShippingAddress(): Promise<ShippingAddressDTO[]> {
    return new Promise<ShippingAddressDTO[]>((resolve, reject) => {
      axios
        .get('/api/store/shipping-address', { useInterceptors: false })
        .then((response) => resolve(response.data.list))
        .catch((error) => {
          console.log('[Shipping-address]: ', error);
          reject(error.response);
        });
    });
  }

  //   배송지 삭제
  async deleteShippingAddress(shippingAddressId: string): Promise<SingleResponse<number> | ErrorResponse> {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/api/store/shipping-address/${shippingAddressId}`, { useInterceptors: false })
        .then((response) => resolve(response.data.data))
        .catch((error) => {
          console.log('[Shipping-address]: ', error);
          reject(error.response);
        });
    });
  }

  /* 배송지 등록 */
  async insertShippingAddress(item: ShippingAddressDTO): Promise<SingleResponse<number> | ErrorResponse> {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/store/shipping-address', item, { useInterceptors: false })
        .then((response) => resolve(response.data.data))
        .catch((error) => {
          console.log('[Shipping-address]: ', error);
          reject(error.response);
        });
    });
  }

  /* 배송지 수정 */
  async updateShippingAddress(item: ShippingAddressDTO): Promise<SingleResponse<number> | ErrorResponse> {
    return new Promise((resolve, reject) => {
      axios
        .put('/api/store/shipping-address', item, { useInterceptors: false })
        .then((response) => resolve(response.data.data))
        .catch((error) => {
          console.log('[Shipping-address]: ', error);
          reject(error.response);
        });
    });
  }

  /** 최근 배송지 */
  async getLatestShippingAddress(): Promise<SingleResponse<ShippingAddressDTO>> {
    return new Promise<SingleResponse<ShippingAddressDTO>>((resolve, reject) => {
      axios
        .get('/api/store/shipping-address/latest', { useInterceptors: false })
        .then((response) => resolve(response.data.data))
        .catch((error) => {
          console.log('[Shipping-address]: ', error);
          reject(error.response);
        });
    });
  }
}

const shippingAddressApi = new ShippingAddressApi();

export default shippingAddressApi;
