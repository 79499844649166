import { NavigateFunction } from 'react-router-dom';
import { removeAuthorizationHeader } from 'src/utils/axios';
import * as cognito from 'src/services/cognito-service';

declare global {
  interface Window {
    flutter_inappwebview: any;
    reloadRouter: any;
    forceSignOut: any;
    pushRouter: any;
    replaceRouter: any;
  }
}

const STORE_MAIN_URL = '/store/main';

const getPathToRedirectUri = () => {
  const path = window.location.href;
  if (path.indexOf('/ssologin') > -1) {
    const searchParams = new URLSearchParams(window.location.search);
    const redirectUri = searchParams.get('returnUrl');
    return redirectUri ?? STORE_MAIN_URL;
  }
  const result = window.location.hash.replace('#', '') || '/';
  return result;
};

// https://stackoverflow.com/questions/11219582/how-to-detect-my-browser-version-and-operating-system-using-javascript
const detectingOS = () => {
  if (navigator.userAgent.indexOf('like Mac') !== -1) return 'iOS';
  if (navigator.userAgent.indexOf('Android') !== -1) return 'Android';
  if (navigator.userAgent.indexOf('Win') !== -1) return 'Windows';
  if (navigator.userAgent.indexOf('Linux') !== -1) return 'Linux';
  if (navigator.userAgent.indexOf('Mac') !== -1) return 'Macintosh';
  return 'Unknown OS';
};

// 오류나서 빈객체 생성
class InAppService {
  isInit: boolean = false;

  isFlutterApp: boolean = false;

  // 장비주 : APP00000000000000016 : com.xpanner.finder.mo
  // 현장관리자 : APP00000000000000017  : com.xpanner.consite.sm
  // 엔지니어 : ?? : com.xpanner.finder.se
  xpnrAppId: string = '';

  version: string = '';

  os: string = 'android'; // ios, other

  showBottomNavigation: boolean = false;

  async init(navigate: NavigateFunction) {
    // 한번만 실행하게..
    if (this.isInit) return;
    this.isInit = true;

    if (!window.flutter_inappwebview) {
      // 플루터앱 인앱브라우저 아닌경우는 여기서 끝남.
      console.log('[InAppService] this browser is not flutter_inappwebview');
      console.log(navigator.userAgent);
      this.showBottomNavigation = true;
      return;
    }
    console.log('[InAppService] this browser is flutter_inappwebview');
    console.log(navigator.userAgent);

    window.pushRouter = (path: string) => navigate(path);
    window.replaceRouter = (path: string) => navigate(path, { replace: true });

    window.forceSignOut = () => {
      removeAuthorizationHeader();
      cognito.signOut();
    };

    const handlerCallback = async (result: any) => {
      const { xpnrAppId, version, accessToken, idToken, refreshToken } = result;

      this.settingMobileAppConfig(xpnrAppId, version);

      if (accessToken !== '' && idToken !== '' && refreshToken !== '') {
        await cognito.setCurrentSession({ accessToken, idToken, refreshToken });
      } else {
        window.forceSignOut();
      }
    };

    // 앱 버전 받아오기
    try {
      if (window.flutter_inappwebview.callHandler) {
        const result = await window.flutter_inappwebview.callHandler('sendAppToWebAppInfo');
        await handlerCallback(result);
      } else {
        const result = await window.flutter_inappwebview._callHandler('sendAppToWebAppInfo');
        await handlerCallback(result);
      }
    } catch (e) {
      console.error(e);
      // provider dispatcher라서 여기서 사용불가.
      // SnackBarUtils.error(e.message || '앱 버전 동기화 중에 오류가 발생했습니다.');
    }
  }

  settingMobileAppConfig = (xpnrAppId: string, version: string) => {
    this.isFlutterApp = true;
    this.xpnrAppId = xpnrAppId;
    this.version = version;
    const os = detectingOS();
    if (os === 'iOS') {
      // flutter Webview 이면 navigator.userAgent 가
      // Mozilla/5.0 (iPhone; CPU iPhone OS 16_0 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/15E148
      // 임
      if (navigator.userAgent.indexOf('AppleWebKit') !== -1) {
        this.showBottomNavigation = false;
      }
    } else if (os === 'Android') {
      // flutter Webview 이면 navigator.userAgent 가
      // Mozilla/5.0 (Linux; Android 12; sdk_gphone64_arm64 Build/S2B2.211203.006; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/104.0.5112.97 Mobile Safari/537.36
      if (navigator.userAgent.indexOf('AppleWebKit') !== -1) {
        this.showBottomNavigation = false;
      }
    }
    this.os = os;
  };

  // 앱에 특정처리를 호출해야 할때  ex) openLoginScreen
  callWebToApp = (
    action:
      | 'openLoginScreen'
      | 'openNotiScreen'
      | 'openPointScreen'
      | 'openAgrTermScreen'
      | 'appLogout'
      | 'goToProfileView',
    data?: string
  ) => {
    console.log('callWebToApp START', action, data);
    window.flutter_inappwebview.callHandler('callWebToApp', action, data).then((result: any) => {
      console.log('callWebToApp END', result);
    });
  };
}

export default new InAppService();
