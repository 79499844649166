import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface KakaoPostcodeProps {
  open: boolean;
  targetId: string;
}

export interface KakaoPostcodeData {
  address: string;
  latitude: number;
  longitude: number;
  zipCode: string;
}

interface KakaoPostcodeState {
  props: KakaoPostcodeProps;
  data: KakaoPostcodeData;
}

const initialState: KakaoPostcodeState = {
  props: {
    open: false,
    targetId: '',
  },
  data: {
    address: '',
    zipCode: '',
    latitude: 0,
    longitude: 0,
  },
};

const kakaoPostcodeSlice = createSlice({
  name: 'kakaoPostcode',
  initialState,
  reducers: {
    openKakaoPostcodeDialog: (state, action) => {
      state.props.open = true;
      state.props.targetId = action.payload;
    },
    closeKakaoPostcodeDialog: (state) => {
      state.props.open = false;
    },
    setAddress: (state: KakaoPostcodeState, action: PayloadAction<string>) => {
      state.data.address = action.payload;
    },
    setData: (state: KakaoPostcodeState, action: PayloadAction<KakaoPostcodeData>) => {
      state.data = action.payload;
    },
    setDefaultData: (state: KakaoPostcodeState) => {
      state.data = {
        address: '',
        zipCode: '',
        latitude: 0,
        longitude: 0,
      };
    },
    setDefaultTargetId: (state: KakaoPostcodeState) => {
      state.props.targetId = '';
    },
  },
});

export const {
  openKakaoPostcodeDialog,
  closeKakaoPostcodeDialog,
  setAddress,
  setData,
  setDefaultData,
  setDefaultTargetId,
} = kakaoPostcodeSlice.actions;

export default kakaoPostcodeSlice.reducer;
