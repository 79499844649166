import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface SweettrackerTrackingProps {
  open: boolean;
  productOrderDetailId: string;
}

interface SweettrackerTrackingState {
  props: SweettrackerTrackingProps;
}

const initialState: SweettrackerTrackingState = {
  props: {
    open: false,
    productOrderDetailId: '',
  },
};

const sweettrackerTrackingSlice = createSlice({
  name: 'sweettrackerTracking',
  initialState,
  reducers: {
    openDeliveryTrackingDialog: (state, action) => {
      const { productOrderDetailId } = action.payload;

      state.props.open = true;
      state.props.productOrderDetailId = productOrderDetailId;
    },
    closeDeliveryTrackingDialog: (state) => {
      state.props.open = false;
      state.props.productOrderDetailId = '';
    },
  },
});

export const { openDeliveryTrackingDialog, closeDeliveryTrackingDialog } = sweettrackerTrackingSlice.actions;

export default sweettrackerTrackingSlice.reducer;
