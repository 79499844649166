import _ from 'lodash';
import numeral from 'numeral';
import { NavigateFunction } from 'react-router-dom';
import { URI_CONFIG } from 'src/config';
import InAppService from 'src/services/InAppService';
import { signOut } from 'src/services/cognito-service';
import SnackBarUtils from './SnackBarUtils';
import { removeAuthorizationHeader } from './axios';

declare global {
  interface Window {
    webToApp: any;
  }
}
const emptyImgSrc = '/assets/images/main/empty.jpg';

// const formatter = new Intl.NumberFormat('ko-KR', { minimumFractionDigits: 0 });
class XpnrUtils {
  static getUrlParameter2(search: string, name: string) {
    const reName = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp(`[\\?&]${reName}=([^&#]*)`);

    const results = regex.exec(search);
    return results === null ? '' : results[1];
  }

  static getCommaNum(val: any) {
    return numeral(val).format();
  }

  // generate unique id for each device(in a browser)
  // https://stackoverflow.com/questions/27247806/generate-unique-id-for-each-device
  static generateDeviceUID() {
    const navigatorInfo = window.navigator;
    const screenInfo = window.screen;
    let uid = `${navigatorInfo.mimeTypes.length}`;
    uid += navigatorInfo.userAgent.replace(/\D+/g, '');
    uid += navigatorInfo.plugins.length;
    uid += screenInfo.height || '';
    uid += screenInfo.width || '';
    uid += screenInfo.pixelDepth || '';
    // console.log({ navigatorInfo, screenInfo, uid });
    return uid;
  }

  static getAttachURL(attachNo: string) {
    if (_.isEmpty(attachNo)) {
      return emptyImgSrc;
    }
    return `/api/v2/cmm/attach/down/${attachNo}`;
  }

  static getThumbnailURL(attachNo: string) {
    if (_.isEmpty(attachNo)) {
      return emptyImgSrc;
    }
    return `/api/lambda/cmm/attach/thumbnail/${attachNo}`;
  }

  // createAsyncThunk 에러 처리 공통으로..
  static catchThunkErrorHandler(err: any, rejectWithValue: (arg0: any) => any) {
    console.error(err);
    SnackBarUtils.error('처리중 에러가 발생하였습니다.');

    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }

  // 공통코드 관련 유틸
  // checkCode: [ { code1: '001', code2: '001' } ]
  // codeStore: { 001_001: Array() }
  // checkCode의 코드값이 codeStore에 모두 존재할경우 true를 출력
  static isCheckCmmCode(checkCode: any, codeStore: { [x: string]: any }) {
    const load = _.map(checkCode, (p) => {
      const key = `${p.code1}|${p.code2}`;
      return !_.isEmpty(codeStore[key]);
    });
    return _.every(load);
  }

  static cmmCodeToName(cod1: any, code2: any, code3: any, codeStore: { [x: string]: any }) {
    const codeList = codeStore[`${cod1}|${code2}`];
    const obj = _.findLast(codeList, (p) => p.code === code3);
    if (obj) return obj.name;
    return 'unknown';
  }

  static goBack = (navigate: NavigateFunction, url: string) => () => {
    if (window.history.state == null || window.history.state.idx === 0) {
      if (url === '/store/mypage') {
        this.goInAppChckNMypage(navigate);
      } else {
        navigate(url, { replace: true });
      }
    } else {
      navigate(-1);
    }
  };

  static goHome = (navigate: NavigateFunction) => () => {
    navigate('/', { replace: true });
  };

  // 인앱브라우저인경우 mypage는 앱화면으로 이동해야 해서...
  // XPNRAPP-28 인앱브라우져 Store웹 마이페이지 이동 시 앱 마이페이지로 이동처리
  static goInAppChckNMypage = (navigate: NavigateFunction) => {
    if (InAppService.isFlutterApp) {
      InAppService.callWebToApp('goToProfileView');
    } else {
      window.location.href = `${URI_CONFIG.mypageUrl}`;
    }
  };

  static goInAppChckNNotiPage = (navigate: NavigateFunction) => {
    if (InAppService.isFlutterApp) {
      InAppService.callWebToApp('openNotiScreen');
    } else {
      window.location.href = `${URI_CONFIG.notiUrl}`;
    }
  };

  static goLoginPage = (pathname: string, navigate: NavigateFunction) => () => {
    if (InAppService.isFlutterApp) {
      // 로그인 안되있고 플루터 인앱뷰인 경우 -> 플루터 로그인 창 뜨게
      InAppService.callWebToApp('openLoginScreen');
      XpnrUtils.goBack(navigate, '/');
    } else {
      const returnTo = `${URI_CONFIG.mainUrl}/#${pathname}`;
      const searchParams = new URLSearchParams({ redirect_uri: returnTo }).toString();
      const href = `${URI_CONFIG.loginUrl}?${searchParams}`;
      window.location.assign(href);
    }
  };

  static signOutofAxiosInterceptor = () => {
    if (InAppService.isFlutterApp) {
      InAppService.callWebToApp('openLoginScreen');
    } else {
      signOut();
      removeAuthorizationHeader();
      window.location.replace(`${URI_CONFIG.loginUrl}?redirect_uri=${window.location.href}`);
    }
  };
}

export default XpnrUtils;
