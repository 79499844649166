import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Slide,
  makeStyles,
} from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'src/store';

import { TransitionProps } from '@material-ui/core/transitions';
import CancelIcon from '@material-ui/icons/Cancel';
import storeOrderApi from 'src/api/StoreOrderApi';
import { SWEETTRACKER_API_KEY } from 'src/config';
import { closeDeliveryTrackingDialog } from 'src/store/xpnr/sweettrackerTrackingSlice';
import { OrderDetailDTO } from 'src/types/StoreOrder';
import LoadingCircularProgress from './LoadingCircularProgress';

const useStyles = makeStyles((theme) => ({
  closeIconBtn: {
    position: 'absolute',
    right: 8,
    top: 4,
    color: theme.palette.grey[500],
  },
  iframeWrap: {
    width: '100%',
    height: '100%',
  },
}));

const Transition = React.forwardRef(
  (props: TransitionProps & { children?: React.ReactElement }, ref: React.Ref<unknown>) => (
    <Slide direction="up" ref={ref} {...props} />
  )
);

const DeliveryTrackingDialog: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { open, productOrderDetailId } = useSelector(({ xpnr }) => xpnr.sweettrackerTracking.props);
  const [orderDetail, setOrederDetail] = useState<OrderDetailDTO | null>(null);

  const callGetOneOrderApi = async () => {
    const result = await storeOrderApi.getOneOrderDetail({ productOrderDetailId });
    setOrederDetail(result);
  };

  useEffect(() => {
    if (productOrderDetailId === '') {
      setOrederDetail(null);
      return;
    }
    callGetOneOrderApi();
  }, [productOrderDetailId]);

  const closeDialog = () => {
    dispatch(closeDeliveryTrackingDialog());
  };

  return (
    <Dialog
      aria-labelledby="sweettracker-tracking-dialog"
      fullScreen={orderDetail ? orderDetail.shippingType === 'ST015001' : false}
      // fullScreen
      open={!!orderDetail && open}
      onClose={closeDialog}
      TransitionComponent={Transition}
    >
      <IconButton aria-label="close" onClick={closeDialog} className={classes.closeIconBtn}>
        <CancelIcon />
      </IconButton>
      {!orderDetail && (
        <Box className={classes.iframeWrap} display="flex" alignItems="center" justifyContent="center">
          <LoadingCircularProgress />
        </Box>
      )}
      {orderDetail?.shippingType === 'ST015001' && (
        <iframe
          className={classes.iframeWrap}
          title="배송정보"
          src={`https://info.sweettracker.co.kr/tracking/5?t_key=${SWEETTRACKER_API_KEY}&t_code=${orderDetail.courier}&t_invoice=${orderDetail.shippingInvoice}`}
        />
      )}
      {orderDetail?.shippingType === 'ST015002' && (
        <>
          <DialogTitle>용달 정보</DialogTitle>
          <DialogContent>
            <DialogContentText>회사: {orderDetail.deliveryComp}</DialogContentText>
            <DialogContentText>배송기사: {orderDetail.driverPhone}</DialogContentText>
          </DialogContent>
        </>
      )}
      {orderDetail?.shippingType === 'ST015003' && (
        <>
          <DialogTitle>방문 수령</DialogTitle>
          <DialogContent>
            <DialogContentText>방문 수령/직접 수령 완료</DialogContentText>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
};

export default DeliveryTrackingDialog;
