import {
  CognitoAccessToken,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession,
  CookieStorage,
} from 'amazon-cognito-identity-js';
import { AWS_CONFIG } from 'src/config';
import { Tokens } from 'src/types/tokens';
import jwtDecode from 'jwt-decode';
import { setAuthorizationHeader } from 'src/utils/axios';

const cookieStorage = new CookieStorage({
  domain: AWS_CONFIG.cognitoCookieDomain,
  secure: AWS_CONFIG.cognitoCookieDomain !== 'localhost',
  sameSite: AWS_CONFIG.cognitoCookieDomain !== 'localhost' ? 'none' : 'lax',
});

const userPool = new CognitoUserPool({
  UserPoolId: AWS_CONFIG.cognitoUserPoolId as string,
  ClientId: AWS_CONFIG.cognitoAppClientId as string,
  Storage: cookieStorage,
});

const createCognitoUserSession = (tokens: Tokens) => {
  if (!tokens || !tokens.accessToken || !tokens.idToken || !tokens.refreshToken) return undefined;
  return new CognitoUserSession({
    AccessToken: new CognitoAccessToken({ AccessToken: tokens.accessToken }),
    IdToken: new CognitoIdToken({ IdToken: tokens.idToken }),
    RefreshToken: new CognitoRefreshToken({ RefreshToken: tokens.refreshToken }),
  });
};

export const setCurrentSession = async (tokens: Tokens) =>
  new Promise<void>((resolve, reject) => {
    const decodedAccesstoken = jwtDecode<{ username: string }>(tokens.accessToken);

    const cognitoUser = new CognitoUser({
      Username: decodedAccesstoken.username,
      Pool: userPool,
      Storage: cookieStorage,
    });
    // console.log('prev createCognitoUserSession', cognitoUser);

    const session = createCognitoUserSession(tokens);
    if (session && session.isValid()) {
      // console.log('after createCognitoUserSession', session);
      setAuthorizationHeader('Bearer', tokens.accessToken);
      cognitoUser.setSignInUserSession(session);
      // SnackBarUtils.success('정상적으로 로그인되었습니다.');
      resolve();
    }
    reject();
  });

export const signOut = async () =>
  new Promise<void>((resolve, reject) => {
    try {
      const cognitoUser = userPool.getCurrentUser();
      if (cognitoUser) {
        cognitoUser.getSession((err: any, session: CognitoUserSession) => {
          if (session?.isValid()) {
            cognitoUser.signOut();
            resolve();
          }
        });
      }
    } catch (e) {
      console.error(e);
      reject(new Error('로그아웃에 실패했습니다.'));
    }
  });

export const globalSignOut = async () =>
  new Promise<void>((resolve, reject) => {
    try {
      const cognitoUser = userPool.getCurrentUser();
      if (cognitoUser) {
        cognitoUser.getSession((err: any, session: CognitoUserSession) => {
          if (session?.isValid()) {
            cognitoUser.globalSignOut({
              onSuccess: () => resolve(),
              onFailure: (err2: any) => {
                throw err2;
              },
            });
          }
        });
      }
    } catch (e) {
      console.error(e);
      reject(new Error('로그아웃에 실패했습니다.'));
    }
  });

export const getCurrentTokens = async (): Promise<Tokens | null> =>
  new Promise<Tokens | null>((resolve, reject) => {
    const cognitoUser = userPool.getCurrentUser();
    if (cognitoUser) {
      cognitoUser.getSession((err: any, session: CognitoUserSession) => {
        if (err) {
          cognitoUser.signOut();
          reject(err);
        }
        if (session?.isValid()) {
          resolve({
            accessToken: session.getAccessToken().getJwtToken(),
            idToken: session.getIdToken().getJwtToken(),
            refreshToken: session.getRefreshToken().getToken(),
          });
        }
      });
    } else {
      resolve(null);
    }
  });

export const refreshTokens = async (): Promise<Tokens | null> =>
  new Promise<Tokens | null>((resolve, reject) => {
    const cognitoUser = userPool.getCurrentUser();
    if (cognitoUser) {
      cognitoUser.getSession((err: any, session: CognitoUserSession) => {
        if (err) {
          cognitoUser.signOut();
          reject(err);
        }
        if (session?.isValid()) {
          const refreshToken = session.getRefreshToken();
          cognitoUser.refreshSession(refreshToken, (rErr, rSession) => {
            if (rErr) {
              cognitoUser.signOut();
              reject(err);
            } else {
              resolve({
                accessToken: rSession.getAccessToken().getJwtToken(),
                idToken: rSession.getIdToken().getJwtToken(),
                refreshToken: rSession.getRefreshToken().getToken(),
              });
            }
          });
        }
      });
    } else {
      resolve(null);
    }
  });

export const checkTokens = async (): Promise<boolean> =>
  new Promise<boolean>((resolve) => {
    const cognitoUser = userPool.getCurrentUser();
    if (cognitoUser) {
      cognitoUser.getSession((err: any, session: CognitoUserSession) => {
        if (err) {
          cognitoUser.signOut();
          resolve(false);
        }
        if (session?.isValid()) {
          cognitoUser.setSignInUserSession(session);
          cognitoUser.getUserAttributes((rErr) => {
            if (rErr) {
              cognitoUser.signOut();
              resolve(false);
            } else {
              resolve(true);
            }
          });
        }
      });
    } else {
      resolve(false);
    }
  });
