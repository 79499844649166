import Axios from 'axios';
import { refreshTokens, setCurrentSession } from 'src/services/cognito-service';
import { Tokens } from 'src/types/tokens';
import SnackBarUtils from './SnackBarUtils';
import XpnrUtils from './XpnrUtils';
// import SnackBarUtils from './SnackBarUtils';

export const setAuthorizationHeader = (tokenType: string, accessToken: string) => {
  Axios.defaults.headers.common.Authorization = `${tokenType} ${accessToken}`;
};

export const removeAuthorizationHeader = () => {
  delete Axios.defaults.headers.common.Authorization;
};

export const setResponseInterceptor = (errCallback: (path: string) => void) => {
  Axios.interceptors.response.use(
    (response) => response,
    (err) =>
      new Promise((/* resolve, reject */) => {
        const { status, data, config } = err.response;
        const { code } = data;

        if (status === 401) {
          if (code === 'C007') {
            SnackBarUtils.error('유효하지 않은 토큰입니다. 토큰갱신처리됩니다.');
            refreshTokens()
              .then((token) => {
                setCurrentSession(token as Tokens);
                window.location.reload();
              })
              .catch((err2) => {
                console.error(err2);
                XpnrUtils.signOutofAxiosInterceptor();
                throw err2;
              });
          }
        }

        // config.useInterceptors = false
        if (config.useInterceptors === false) {
          throw err;
        }

        // SnackBarUtils.error(`${status} error`);

        switch (status) {
          case 400:
            errCallback('/400');
            break;
          case 401:
            errCallback('/401');
            break;
          case 403:
            errCallback('/403');
            break;
          case 500:
          default:
            errCallback('/500');
            break;
        }
      })
  );
};
