import Axios from 'axios';
import { StoreConfigDTO } from 'src/types/storeConfig';

declare module 'axios' {
  export interface AxiosRequestConfig {
    useInterceptors?: boolean;
  }
}

class XpnrConfigApi {
  async getListStoreConfig(): Promise<StoreConfigDTO> {
    return new Promise((resolve, reject) => {
      Axios.get('/api/store/config', { useInterceptors: false })
        .then((response) => resolve(response.data.data))
        .catch((error) => {
          console.error('[StoreXpnrConfigApi]: ', error);
          reject(error.response);
        });
    });
  }
}

const xpnrConfigApi = new XpnrConfigApi();

export default xpnrConfigApi;
