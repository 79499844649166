import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import gtm from 'src/lib/gtm';

const RouteChangeTracker = () => {
  const location = useLocation();

  useEffect(() => {
    setTimeout(() => {
      ReactGA.send('pageview');
    }, 500);
  }, [location]);

  return <div />;
};

export default RouteChangeTracker;
