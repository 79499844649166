import { Box, InputAdornment, SvgIcon, TextField, makeStyles } from '@material-ui/core';
import type { FC, KeyboardEvent, KeyboardEventHandler, MouseEventHandler } from 'react';
import { useState } from 'react';
import { Search as SearchIcon } from 'react-feather';
import { useNavigate, useParams } from 'react-router';

interface Result {
  description: string;
  title: string;
}

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    width: '384px',
    height: '48px',
    borderRadius: '6px',
    background: '#F2F4F6',
    padding: '0 16px',
  },
}));

const Search: FC = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const { keyword } = useParams();
  const [value, setValue] = useState<string | undefined>(keyword);

  const goSearchPage = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      navigate(`/store/search/${value}`);
    }
  };

  return (
    <>
      <Box className={classes.root}>
        <TextField
          onKeyDown={goSearchPage}
          fullWidth
          InputProps={{
            disableUnderline: true,
            startAdornment: (
              <InputAdornment position="start">
                <SvgIcon fontSize="small" color="action">
                  <SearchIcon />
                </SvgIcon>
              </InputAdornment>
            ),
          }}
          onChange={(event) => setValue(event.target.value)}
          placeholder="스패너 스토어에서 검색해보세요."
          value={value}
          variant="standard"
        />
      </Box>
    </>
  );
};

export default Search;
