import type { FC, ReactNode } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';

import { useAuth } from 'src/hooks/useAuth';
import XpnrUtils from 'src/utils/XpnrUtils';

interface AuthGuardProps {
  children: ReactNode;
}

export const AuthGuard: FC<AuthGuardProps> = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();
  const [checked, setChecked] = useState<boolean>(false);

  const goLoginPage = XpnrUtils.goLoginPage(location.pathname, navigate);

  const check = useCallback(() => {
    if (!isAuthenticated) {
      goLoginPage();
    } else {
      setChecked(true);
    }
  }, [isAuthenticated, location.pathname]);

  // Only check on mount, this allows us to redirect the user manually when auth state changes
  useEffect(
    () => {
      check();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isAuthenticated]
  );

  if (!checked) {
    return null;
  }

  // If got here, it means that the redirect did not occur, and that tells us that the user is
  // authenticated / authorized.

  return <>{children}</>;
};
