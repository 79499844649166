import Axios, { AxiosResponse } from 'axios';
import { CommonResponse, ErrorResponse } from 'src/types/response';
import { ShoppingBasketDTO, ShoppingBasketListDTO } from 'src/types/ShoppingBasket';

declare module 'axios' {
  export interface AxiosRequestConfig {
    useInterceptors?: boolean;
  }
}

class ShopingBasketApi {
  async getListShoppingBasket(): Promise<ShoppingBasketListDTO[]> {
    return new Promise((resolve, reject) => {
      Axios.get('/api/store/shopping-basket', { useInterceptors: false })
        .then((response) => resolve(response.data.list))
        .catch((error) => {
          console.error('[ShopingBasketApi]: ', error);
          reject(error.response);
        });
    });
  }

  async updateCnt(item: ShoppingBasketDTO): Promise<null> {
    return new Promise<null>((resolve, reject) => {
      Axios.put('/api/store/shopping-basket', item, { useInterceptors: false })
        .then((response) => resolve(response.data))
        .catch((error) => {
          console.error('[ShopingBasketApi]: ', error);
          reject(error.response);
        });
    });
  }

  // 제품설명에서 장바구니 담기누르면 실행함.
  async updateShoppingBasketByProductInfoId(productList: ShoppingBasketDTO[]): Promise<CommonResponse> {
    return new Promise<CommonResponse>((resolve, reject) => {
      const formdata = new FormData();
      formdata.append('productList', JSON.stringify(productList));
      Axios.put('/api/store/shopping-basket/product', formdata, { useInterceptors: false })
        .then((response) => resolve(response.data.data))
        .catch((error) => {
          console.error('[ShopingBasketApi]: ', error);
          reject(error.response);
        });
    });
  }

  async deleteShoppingBasket(shoppingBasketId: number): Promise<AxiosResponse<CommonResponse, ErrorResponse>> {
    return new Promise((resolve, reject) => {
      Axios.delete(`/api/store/shopping-basket/${shoppingBasketId}`, { useInterceptors: false })
        .then((response) => resolve(response))
        .catch((error) => {
          console.error('[ShopingBasketApi]: ', error);
          reject(error.response);
        });
    });
  }

  async deleteCheckedShoppingBasket(
    shoppingBasketIds: number[]
  ): Promise<AxiosResponse<CommonResponse, ErrorResponse>> {
    return new Promise((resolve, reject) => {
      Axios.delete(`/api/store/shopping-basket/${shoppingBasketIds}/checked`, { useInterceptors: false })
        .then((response) => resolve(response))
        .catch((error) => {
          console.error('[ShopingBasketApi]: ', error);
          reject(error.response);
        });
    });
  }
}
const shoppingBasketApi = new ShopingBasketApi();

export default shoppingBasketApi;
