export const THEMES = {
  LIGHT: 'LIGHT',
  ONE_DARK: 'ONE_DARK',
};

const AWS_S3_ROOT_FOLDER = process.env.REACT_APP_AWS_S3_ROOT_FOLDER;

const SWEETTRACKER_API_KEY = process.env.REACT_APP_SWEETTRACKER_API_KEY;

const URI_CONFIG = {
  mainUrl: `${window.location.origin}`,
  loginUrl: process.env.REACT_APP_LOGIN_URL,
  mypageUrl: `${process.env.REACT_APP_ACCOUNT_URI}/mypage`,
  notiUrl: `${process.env.REACT_APP_ACCOUNT_URI}/notification`,

  finder_mo_uri: process.env.REACT_APP_FINDER_MO_URI || '',
  rent_worker_uri: process.env.REACT_APP_RENT_WORKER_URI || '',
  account_uri: process.env.REACT_APP_ACCOUNT_URI || '',

  iamport_redirect_uri: process.env.REACT_APP_IAMPORT_REDIRECT_URI || '',
  shipping_ref_uri: process.env.REACT_APP_SHIPPING_REF_URI || '',
};

const GA_CONFIG = {
  MeasurementId: process.env.REACT_APP_GA_MEASUREMENT_ID || '',
};

const AWS_CONFIG = {
  region: process.env.REACT_APP_AWS_REGION,
  cognitoAppClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
  cognitoUserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  cognitoCookieDomain: process.env.REACT_APP_COGNITO_COOKIE_DOMAIN,
};

const IAMPORT_CONFIG = {
  impId: process.env.REACT_APP_IAMPORT_IMP_ID,
};

export { AWS_CONFIG, AWS_S3_ROOT_FOLDER, GA_CONFIG, IAMPORT_CONFIG, SWEETTRACKER_API_KEY, URI_CONFIG };
