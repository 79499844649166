import _ from 'lodash';
import { createContext, FC, ReactNode, useEffect, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';
import codeApi from 'src/api/CodeApi';
import xpnrConfigApi from 'src/api/XpnrConfigApi';
import InAppService from 'src/services/InAppService';
import { StoreConfigDTO } from 'src/types/storeConfig';
import SnackBarUtils from 'src/utils/SnackBarUtils';
import { CodeDTO, CodeParam } from '../types/code';

export interface XpnrCodeState {
  [key: string]: CodeDTO[];
}

export interface XpnrState {
  code: XpnrCodeState;
  storeConfig: StoreConfigDTO;
  isInAppInit: boolean;
}

interface XpnrProviderProps {
  children: ReactNode;
}

type GetMultiCodelistAction = {
  type: 'GET_MULTI_CODELIST';
  payload: XpnrCodeState;
};

type ComplateInappInitAction = {
  type: 'COMPLATE_INAPP_INIT';
};

type GetListStoreConfig = {
  type: 'GET_LIST_STORE_CONFIG';
  payload: StoreConfigDTO;
};

type Action = GetMultiCodelistAction | ComplateInappInitAction | GetListStoreConfig;

const handlers: Record<string, (state: XpnrState, action: any) => XpnrState> = {
  GET_MULTI_CODELIST: (state: XpnrState, action: GetMultiCodelistAction): XpnrState => {
    const codes = action.payload;
    return {
      ...state,
      code: {
        ...state.code,
        ...codes,
      },
    };
  },
  COMPLATE_INAPP_INIT: (state: XpnrState, action: ComplateInappInitAction): XpnrState => ({
    ...state,
    isInAppInit: true,
  }),
  GET_LIST_STORE_CONFIG: (state: XpnrState, action: GetListStoreConfig): XpnrState => {
    const storeConfigs = action.payload;

    return {
      ...state,
      storeConfig: {
        ...state.storeConfig,
        ...storeConfigs,
        isSetting: true,
      },
    };
  },
};

const reducer = (state: XpnrState, action: Action): XpnrState =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

export interface XpnrContextValue extends XpnrState {
  getMultiCodeList: (paramCode: CodeParam[]) => Promise<void>;
  getListStoreConfig: () => Promise<void>;
}

const initialState: XpnrState = {
  code: {},
  isInAppInit: false,
  storeConfig: {
    defaultFreeShippingAmount: 0,
    defaultShippingFee: 0,
    isSetting: false,
  },
};

const XpnrContext = createContext<XpnrContextValue>({
  ...initialState,
  getMultiCodeList: () => Promise.resolve(),
  getListStoreConfig: () => Promise.resolve(),
});

export const XpnrProvider: FC<XpnrProviderProps> = ({ children }) => {
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(reducer, initialState);

  async function init() {
    await InAppService.init(navigate);
    dispatch({ type: 'COMPLATE_INAPP_INIT' });
  }

  useEffect(() => {
    init();
  }, []);

  // paramCode : [{ code1: '001', code2: '001' }]
  const getMultiCodeList = async (paramCode: any) => {
    const codeStore = state.code;

    // 001_001 -> { code1: '001', code2: '001' }
    const existCodes: CodeParam[] = _.chain(codeStore)
      .keys()
      .map((p) => {
        const codes = p.split('|');
        return { code1: codes[0], code2: codes[1] };
      })
      .value();

    const chkParamCode: CodeParam[] = _.xorWith(paramCode, existCodes, _.isEqual);
    if (_.isEmpty(chkParamCode)) return;

    const response: XpnrCodeState = await codeApi.getMultiCodeList(paramCode);
    dispatch({
      type: 'GET_MULTI_CODELIST',
      payload: response,
    });
  };

  const getListStoreConfig = async () => {
    try {
      const response = await xpnrConfigApi.getListStoreConfig();

      if (!response.defaultFreeShippingAmount || !response.defaultShippingFee) {
        throw new Error();
      }

      dispatch({
        type: 'GET_LIST_STORE_CONFIG',
        payload: response,
      });
    } catch (error) {
      SnackBarUtils.error('필요한 값을 불러오는데 실패했습니다. 잠시후 다시 시도해주세요');
    }
  };

  return (
    <XpnrContext.Provider
      value={{
        ...state,
        getMultiCodeList,
        getListStoreConfig,
      }}
    >
      {children}
    </XpnrContext.Provider>
  );
};

export const XpnrConsumer = XpnrContext.Consumer;

export default XpnrContext;
