import { Badge, IconButton } from '@material-ui/core';
import { FC, useEffect } from 'react';
import { ShoppingCart as ShoppingCartIcon } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'src/hooks/useAuth';
import { useDispatch, useSelector } from 'src/store';
import { getListShoppingBasket, initShoppingBasket } from 'src/store/store/shoppingBasketSlice';

const ShoppingCartButton: FC = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();

  const dispatch = useDispatch();
  const { list } = useSelector(({ store }) => store.shoppingBasket);

  useEffect(() => {
    if (isAuthenticated) {
      (async () => {
        await dispatch(getListShoppingBasket());
      })();
    } else {
      dispatch(initShoppingBasket());
    }
  }, []);

  const goShoppingBasketPage = () => {
    navigate('/store/shopping-basket');
  };
  return (
    <IconButton aria-label="shopping basket" onClick={goShoppingBasketPage}>
      <Badge {...(list.length === 0 ? {} : { badgeContent: list.length })} color="error" overlap="rectangular">
        <ShoppingCartIcon />
      </Badge>
    </IconButton>
  );
};

export default ShoppingCartButton;
