import { combineReducers } from '@reduxjs/toolkit';
import prodcut from './productSlice';
import shoppingBasket from './shoppingBasketSlice';
import checkout from './checkoutSlice';

const storeReducer = combineReducers({
  prodcut,
  shoppingBasket,
  checkout,
});

export default storeReducer;
