import { createAsyncThunk, createSlice, current, PayloadAction } from '@reduxjs/toolkit';

import { differenceInMinutes } from 'date-fns';
import productRestoreApi from 'src/api/ProductRestoreApi';
import shoppingBasketApi from 'src/api/ShoppingBasketApi';
import { ShoppingBasketDTO, ShoppingBasketListDTO } from 'src/types/ShoppingBasket';
import XpnrStoreUtils from 'src/utils/XpnrStoreUtils';
import XpnrUtils from 'src/utils/XpnrUtils';
import { RootState } from '..';

interface ShoppingBasketState {
  list: ShoppingBasketListDTO[];
  selectIds: number[];
  listPrice: number; // 할인 적용 전 가격
  discountAmount: number; // 할인금액
  salesPrice: number; // 할인 적용 후 가격
  taxAmount: number; // 부가세
  subTotalAmount: number; // 상품금액 + 부가세
  updateDt?: Date;
}

const initialState: ShoppingBasketState = {
  list: [],
  selectIds: [],
  listPrice: 0,
  discountAmount: 0,
  salesPrice: 0,
  taxAmount: 0,
  subTotalAmount: 0,
};

// TODO: getState()로 로그인 여부 체크

export const getListShoppingBasket = createAsyncThunk<ShoppingBasketListDTO[], { force?: boolean } | void>(
  'shoppingBasket/getShoppingBasketList',
  async (prop, { getState }) => {
    // force 가 true  이면 시간계산안하고 바로 서버조회
    const force = prop && prop.force;
    if (force) {
      const list = await shoppingBasketApi.getListShoppingBasket();
      return list;
    }

    const {
      store: {
        shoppingBasket: { list, updateDt },
      },
    } = getState() as RootState;
    // updateDt가 없으면 조회
    if (!updateDt) {
      const newList = await shoppingBasketApi.getListShoppingBasket();
      return newList;
    }
    // updateDt 계산해서 30분 이상 지났으면 조회
    const min = differenceInMinutes(new Date(), updateDt);
    if (min > 30) {
      const newList = await shoppingBasketApi.getListShoppingBasket();
      return newList;
    }
    return list;
  }
);

export const updateCnt = createAsyncThunk('shoppingBasket/updateCnt', async (item: ShoppingBasketDTO) => {
  shoppingBasketApi.updateCnt(item);
  return item;
});

export const deleteShoppingBasket = createAsyncThunk('shoppingBasket/delete', async (shoppingBasketId: number) => {
  await shoppingBasketApi.deleteShoppingBasket(shoppingBasketId);

  return shoppingBasketId;
});

export const deleteCheckedShoppingBasket = createAsyncThunk(
  'shoppingBasket/deleteChecked',
  async (shoppingBasketIds: number[]) => {
    await shoppingBasketApi.deleteCheckedShoppingBasket(shoppingBasketIds);

    return shoppingBasketIds;
  }
);

// 재입고 요청
export const reqShopItemRestore = createAsyncThunk<string, { productInfoId: string }>(
  'shoppingBasket/requestProductRestock',
  async ({ productInfoId }, { rejectWithValue }) => {
    try {
      if (!productInfoId) {
        throw new Error('productInfoId Parameter is undefined!!');
      }
      await productRestoreApi.request(productInfoId);
      return productInfoId;
    } catch (err) {
      return XpnrUtils.catchThunkErrorHandler(err, rejectWithValue);
    }
  }
);

const calcPrice = (state: ShoppingBasketState) => {
  const { list, selectIds } = current(state);

  const selectedList = list.filter((prod) => selectIds.includes(prod.shoppingBasketId));
  const listPrice = selectedList.reduce(
    (total, item) =>
      // 할인율 적용 전 가격
      total + item.displayPrice * item.cnt,
    0
  );
  const salesPrice = selectedList.reduce((total, item) => {
    // 할인 적용 후 가격
    const price = XpnrStoreUtils.getCalcDisplayPrice(item);
    return total + price * item.cnt;
  }, 0);
  const discountAmount = listPrice - salesPrice; // 할인된 금액 (할인금액 + 1원절삭 금액)

  const taxAmount = Math.floor(salesPrice / 100) * 10; // 금액 원단위 절삭처리;
  const subTotalAmount = salesPrice + taxAmount;

  state.listPrice = listPrice;
  state.salesPrice = salesPrice;
  state.discountAmount = discountAmount;
  state.taxAmount = taxAmount;
  state.subTotalAmount = subTotalAmount;
};

const shoppingBasketSlice = createSlice({
  name: 'shoppingBasket',
  initialState,
  reducers: {
    setSelectIds: (state, action: PayloadAction<number[]>) => {
      state.selectIds = action.payload;
      calcPrice(state);
    },
    setShoppingBasket: (_, action: PayloadAction<ShoppingBasketState>) => ({
      ...action.payload,
    }),
    initShoppingBasket: () => ({ ...initialState }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(getListShoppingBasket.fulfilled, (state, action) => {
        const list = action.payload;
        state.list = list;
        state.selectIds = list.filter((p) => p.orderQty > 0 && p.orderQty >= p.cnt).map((p) => p.shoppingBasketId);
        state.updateDt = new Date();
        calcPrice(state);
      })
      .addCase(updateCnt.fulfilled, (state, { payload }) => {
        const changeItem = state.list.find((v) => v.shoppingBasketId === payload.shoppingBasketId);
        if (changeItem) changeItem.cnt = payload.cnt;
        calcPrice(state);
      })
      .addCase(deleteShoppingBasket.fulfilled, (state, { payload: shoppingBasketId }) => {
        state.list = state.list.filter((v) => v.shoppingBasketId !== shoppingBasketId);
        state.updateDt = undefined;
      })
      .addCase(deleteCheckedShoppingBasket.fulfilled, (state, { payload: shoppingBasketIds }) => {
        state.list = state.list.filter((v) => !shoppingBasketIds.includes(v.shoppingBasketId));
        state.updateDt = undefined;
      })
      .addCase(reqShopItemRestore.fulfilled, (state, action) => {
        const productInfoId = action.payload;
        const product = state.list.find((v) => v.productInfoId === productInfoId);

        if (product) product.restoreReqYn = !product.restoreReqYn;
      });
  },
});

export const { setSelectIds, setShoppingBasket, initShoppingBasket } = shoppingBasketSlice.actions;

export default shoppingBasketSlice.reducer;
