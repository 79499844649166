import React from 'react';
import type { FC } from 'react';

interface LogoProps {
  [key: string]: any;
}

const Logo: FC<LogoProps> = (props) => <img alt="Logo" src="/assets/images/logos/xpanner-logo.svg" {...props} />;

export default Logo;
