import { AppBar, Box, IconButton, Toolbar, Typography, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import type { FC } from 'react';
import { Bell as BellIcon } from 'react-feather';
import { useNavigate } from 'react-router';
import ShoppingCartButton from 'src/components/ShoppingCartButton';
import { useAuth } from 'src/hooks/useAuth';
import XpnrUtils from 'src/utils/XpnrUtils';

interface MobileTopBarProps {
  className?: string;
}

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 700,
    color: '#004727',
  },
}));

const MobileTopBar: FC<MobileTopBarProps> = ({ className, ...rest }) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const { isAuthenticated } = useAuth();

  const goNotiPage = () => XpnrUtils.goInAppChckNNotiPage(navigate);

  return (
    <AppBar color="inherit" elevation={0}>
      <Toolbar>
        <Typography className={classes.title} variant="h3" color="textPrimary" align="center" noWrap>
          Store
        </Typography>
        <Box flexGrow={1} display="flex" justifyContent="flex-end">
          {isAuthenticated && (
            <IconButton aria-label="bell" onClick={goNotiPage}>
              <BellIcon />
            </IconButton>
          )}
          <ShoppingCartButton />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

MobileTopBar.propTypes = {
  className: PropTypes.string,
};

export default MobileTopBar;
